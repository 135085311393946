import React, {Component} from 'react';
import cs from './propertyContactContainer.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import propertyContact from "../../assets/images/campground/MVPD.jpeg"

class PropertyContactContainer extends Component {
  render(){
    return (
      	<div className={cs.campground_contact_container}>
      		<img className={cs.property_contact_img} src={propertyContact} alt={"Property Contact"}/>
      		<div className={cs.no_wrap}>
		    	<b>Range/Campground Questions:</b>
		    </div>
		    <div className={cs.no_wrap}>
		    	<FontAwesomeIcon icon={'envelope'} />
		    	<span className={cs.email}> propertyscheduling@sccpoa.org </span>
		    </div>
	    </div>
    )
  }
}

export default PropertyContactContainer;
