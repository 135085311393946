import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import cs from "./donationPanel.module.css";
import CardComponent from "../Card/card";
import MainDonate from "../../assets/images/mainDonate.jpg";
import MainFundraising from "../../assets/images/mainFundraising.jpg";
import GiveAWeekend from "../../assets/images/give a weekend.jpg";
import Raffle from "../../assets/images/raffle/2022 website raffle ticket image-0003.jpg";
import JoinUs from "../../assets/images/memberships.jpg";
// import JoinUs from "../../assets/images/join_us_now.jpg";
// import Smile from "../../assets/images/amazon smile.jpg";

class DonationPanel extends Component {
  render() {
    return (
      <div>
        <Container>
          <div className={cs.border}>
            <h2 className={cs.top_text}> Ways to Support the SCCPOA!</h2>
            <Row>
              <CardComponent
                src={MainDonate} 
                header={"Donate Today"}
                href={"/directDonationsSCCPOA"}
              />
              <CardComponent src={JoinUs} header={"Memberships"} href={"/newAndRenewal"}/> 
              <CardComponent
                src={MainFundraising}
                header={"Youth Outreach"}
                href={"/youthOutreach"}
              />
               
             {/* <CardComponent src={Raffle} header={"Raffle"} href={"/raffle"} />*/}
              {/*<CardComponent
                src={Smile}
                header={"Amazon Smile"}
                href={
                  "https://smile.amazon.com/gp/chpf/homepage/ref=smi_chpf_redirect?ie=UTF8&ein=82-5444902&ref_=smi_ext_ch_82-5444902_cl"
                }
              />*/}
              <CardComponent src={GiveAWeekend} header={"Volunteer"} href={"/volunteer"}/> 
              
            </Row>
          </div>
        </Container>
      </div>
    );
  }
}

export default DonationPanel;
