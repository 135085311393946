import React, {Component} from 'react';
import Carousel from 'react-bootstrap/Carousel';
import cs from './businessSupporters.module.css'


class BusinessSupporters extends Component {
  	render(){
  		let {supporters} = this.props
      console.log('supporters', supporters)

    	return (
          <div>
            <p className={cs.top_text}>Business Supporters</p>
            <Carousel className={cs.carousel}>
            {
              supporters.map((supporter, i)=>
                <Carousel.Item key={i}>
                      <div className={cs.image_container}>
                        <a href={supporter.href}>
                          <img className={cs.image} src={supporter.img} alt="Business Supporter"/>
                        </a>
                      </div>
                    </Carousel.Item>      
                  )
            }
            </Carousel>

            <p align="center">
              If you are interested in becoming an SCCPOA business supporter, please contact fundraising@sccpoa.org
            </p>

          </div>
      		
    	)
    
  	}
}

export default BusinessSupporters;




