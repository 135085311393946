import React, { Component } from "react";
import Header from "../../Common/Components/Header/header.jsx";
import Donations from "../../Common/Components/DonationPanel/donationPanel.jsx";
import Footer from "../../Common/Components/Footer/footer.jsx";
import PageTitle from "../../Common/Components/PageTitle/pageTitle.jsx";
import PDFLink from "../../Common/Components/PDFLink/pdfLink.jsx";
import DonateToday from "../../Common/Components/DonateToday/donateToday.jsx";
import TopImage from "../../Common/assets/images/donations/topImage.JPG";
import BobyTaylor from "../../Common/assets/images/bobby taylor.jpg";
import cs from "./raffle.module.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import RaffleImg from "../../Common/assets/images/raffle/2023_raffle_winning_tickets.jpg";
import Pdf from "../../Common/assets/pdf/2023_raffle_flier_winning_tickets.pdf";

class Fundraising extends Component {
  render() {
    return (
      <div>
        <Header />
        <Container>
          <PageTitle title="2023 Raffle" />
          <a href={Pdf}>
            {/*<img className={cs.img} src={FundraisingImg}/>*/}
            <img className={cs.img} src={RaffleImg} />
          </a>
          {
            // <object data="assets/pdf/fundraising.pdf" type="application/pdf" width="95%" height="600em">
            //   <p><b>Example fallback content</b>: This browser does not support PDFs. Please download the PDF to view it: <a href="/pdf/sample-3pp.pdf">Download PDF</a>.</p>
            // </object>
          }
          {/*
          <hr />
          <Row>
            <Col sm={12}>
              <div>
                <h3 align="left">
                  {" "}
                  <b>Purchase Your Raffle Ticket Here Today!</b>
                </h3>
                <p className={cs.bold}>
                  Purchases should be sent to our P.O. box or by Paypal/Credit
                  Card below:
                </p>

                <Row>
                  <Col>
                    <div className={cs.payment_heading}>
                      Payments made by Paypal/Credit Card:
                    </div>
                    <form
                      action="https://www.paypal.com/cgi-bin/webscr"
                      method="post"
                      target="_top"
                    >
                      <input type="hidden" name="cmd" value="_s-xclick" />
                      <input
                        type="hidden"
                        name="hosted_button_id"
                        value="L8N7QEDLRYQEN"
                      />
                      <input
                        type="image"
                        src="https://www.paypalobjects.com/en_US/i/btn/btn_buynowCC_LG.gif"
                        border="0"
                        name="submit"
                        alt="PayPal - The safer, easier way to pay online!"
                      />
                      <img
                        alt=""
                        border="0"
                        src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif"
                        width="1"
                        height="1"
                      />
                    </form>
                  </Col>
                  <Col>
                    <div>
                      <div className={cs.payment_heading}>
                        Payments made by check to:
                      </div>
                      <p>
                        Santa Clara County Peace Officer's Association
                        <br />
                        P.O. Box 4629
                        <br />
                        Mountain View, Ca 94040
                        <br />
                      </p>
                    </div>
                  </Col>
                </Row>
                {comment {
                  <table>
                    <tr>
                      <th>Payments made by PayPal:</th>
                      <th>Payments made by check to:</th>
                    </tr>
                    <tr>
                      <td>
                        <form
                          action="https://www.paypal.com/cgi-bin/webscr"
                          method="post"
                          target="_top"
                        >
                          <input type="hidden" name="cmd" value="_s-xclick" />
                          <input
                            type="hidden"
                            name="hosted_button_id"
                            value="7VDFM7LYQMVHN"
                          />
                          <input
                            type="image"
                            src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif"
                            border="0"
                            name="submit"
                            alt="PayPal - The safer, easier way to pay online!"
                          />
                          <img
                            alt=""
                            border="0"
                            src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif"
                            width="1"
                            height="1"
                          />
                        </form>
                      </td>
                      <td>
                        Santa Clara County Peace Officer's Association
                        <br />
                        P.O. Box 4629
                        <br />
                        Mountain View, Ca 94040
                        <br />
                      </td>
                    </tr>
                  </table>
                } comment}

                <br />
                <p>
                  Note: The SCCPOA is a tax-exempt organization under Section
                  501 c(4) of the Internal Revenue Code. This gift is considered
                  tax-deductible as a charitable contribution for Federal Income
                  Tax purposes. Federal Tax ID # 94-6122042.
                </p>
                <p>
                  Any charitable donation is 100% Tax Deductible! No funds
                  received by the association are used outside the County. You
                  may be able to deduct your donation as trade or business
                  expenses, if ordinary and necessary in the conduct of the
                  taxpayer’s business. Please consult your tax advisor as to how
                  your support may be deducted. If you need a letter from us
                  please email <b>secretary@sccpoa.org</b>.
                </p>
                <p>
                  *Donation refund policy: Due to the nature of donations,
                  refunds are not offered. Secure Checkout is provided through
                  PayPal.
                </p>
                <p>Thank you for your support!</p>
                <p>
                  Serving the disadvantaged youth and needy of Santa Clara
                  County for over 51 years.
                </p>
              </div>
            </Col>
          </Row>
              */}
        </Container>
        <Donations />
        <Footer />
      </div>
    );
  }
}

export default Fundraising;
