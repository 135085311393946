import React, { Component } from "react";
import Header from "../../Common/Components/Header/header.jsx";
import Donations from "../../Common/Components/DonationPanel/donationPanel.jsx";
import Footer from "../../Common/Components/Footer/footer.jsx";
import cs from "./charitableFundraising.module.css";
import Container from "react-bootstrap/Container";
import ThankYouForYourSupportSection from "../../Common/Components/ThankYouForSupportSection/thankYouForSupportSection.jsx"
import fundraising_heading_img from '../../Common/assets/images/charitableFoundation/fundraising_heading_img.jpg'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import fundraising_pdf_img from '../../Common/assets/images/charitableFundraising/fundraising_flier.jpg'
import sccpoa_fundraising_pdf_img from '../../Common/assets/images/charitableFundraising/sccpoa_fundraising_flier.png'
import sccpof_fundraising_pdf_img from '../../Common/assets/images/charitableFundraising/sccpof_fundraising_flier.png'

//https://kerncountyffu.firstresponderprocessing.com/

class CorpoateCharitableFundraising extends Component {
  constructor(props){
    super(props);
    this.state={
      isSCCPOASelected: true,
      isSCCPOFSelected: false
    }
  }

  donationToSCCPOASelected(){
    this.setState({isSCCPOASelected:true, isSCCPOFSelected:false})
  }

  donationToSCCPOFSelected(){
    this.setState({isSCCPOASelected:false, isSCCPOFSelected:true})
  }

  render() {
    return (
      <div>
        <Header />
        <Container>
          {/* <PageTitle title="Fundraising Charitable Association, SCCPOA" /> */}
          <div className={cs.fundraising_heading_container}>
            <img className={cs.fundraising_img} src={fundraising_heading_img} />
            <div className={cs.fundraising_heading_text}>Make a difference with your donation</div>
          </div>
          
          <p className={cs.instruction_titles}>Welcome!</p>

        <Row>
          <Col sm={12} md={8}>
            <div className={cs.instruction_subtitles}>I am donating to</div>
            <div className={cs.donating_to_container}>
              <div>
                <label>
                  <input checked={this.state.isSCCPOASelected} value="SCCPOA" onChange={this.donationToSCCPOASelected.bind(this)} className={cs.donate_to_radio_button} type="radio"/>
                </label>
              </div>
              <div>
                  <b>The Santa Clara County Peace Officers’ Association</b>
                <div>
                  is a non-profit 501(c)(4) and provides services to many
                  organizations within Santa Clara County including
                  underprivileged, disadvantaged, and/or at-risk youth in
                  order to interact in a positive way with law enforcement
                  officers while using our property.
                </div>
              </div>
            </div>
            <div className={cs.donating_to_container}>
              <div>
                <label>
                  <input checked={this.state.isSCCPOFSelected} value="SCCPOF" onChange={this.donationToSCCPOFSelected.bind(this)} className={cs.donate_to_radio_button} type="radio"/>
                </label>
              </div>
              <div>
                  <b>The Santa Clara County Peace Officers’ Foundation</b>
                <div>
                  is a non-profit 501(c)(3). The Foundation provides
                  financial support for the SCCPOA property and projects.
                </div>
              </div>
            </div>

            <div className={cs.donation_button}>
              {this.state.isSCCPOASelected &&
                <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
                  <input type="hidden" name="cmd" value="_s-xclick" />
                  <input type="hidden" name="hosted_button_id" value="7VDFM7LYQMVHN"/>
                  <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif" border="0" name="submit" alt="PayPal - The safer, easier way to pay online!"/>
                  <img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1" />
                </form>
              }
              {this.state.isSCCPOFSelected &&
                <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
                  <input type="hidden" name="cmd" value="_s-xclick" />
                  <input type="hidden" name="hosted_button_id" value="ZAM2QPXDBM9KY"/>
                  <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif" border="0" name="submit" alt="PayPal - The safer, easier way to pay online!"/>
                  <img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1" />
                </form>
              }
            </div>
          </Col>    
          <Col sm={12} md={4}>
            {this.state.isSCCPOASelected &&
              <div className={cs.fundraising_pdf_container}>
                <div className={cs.donation_letter_text}>SCCPOA Donation Letter</div>
                <a href="assets/pdf/charitableFundraising/sccpoa_corporate_fundraising_flier.pdf">
                  <img className={cs.fundraising_pdf_img} src={sccpoa_fundraising_pdf_img} />
                </a>
              </div>
            }
            {this.state.isSCCPOFSelected &&
              <div className={cs.fundraising_pdf_container}>
              <div className={cs.donation_letter_text}>SCCPOF Donation Letter</div>
              <a href="assets/pdf/charitableFundraising/sccpof_corporate_fundraising_flier.pdf">
                <img className={cs.fundraising_pdf_img} src={sccpof_fundraising_pdf_img} />
              </a>
            </div>
            }
            
          </Col>      
        </Row> 
        <ThankYouForYourSupportSection/>
        </Container>
        <Donations />
        <Footer />
      </div>
    );
  }
}

export default CorpoateCharitableFundraising;
