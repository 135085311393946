import React, { Component } from "react";
import Tab from "react-bootstrap/Tab";
import Button from "react-bootstrap/Button";
import cs from "../membership.module.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ApplicationForm from "../../../assets/pdf/MASTER membership application.pdf";
import PDFLink from "../../PDFLink/pdfLink.jsx";
import hhaForm from "../../../assets/pdf/2021 Master Range Use Agreement and Range Rules rev a.pdf";

class RequiredDocuments extends Component {
  render() {
    let { memberType, cartDetails } = this.props;

    return (
      <div>
        <p className={cs.step_header}>Required Documents</p>
        <p>
          {memberType === "new"
            ? "To Process your New membership application, we need the following documents to be filled out and returned either by email (secretary@sccpoa.org) or by US mail (SCCPOA, PO Box 4629, Mt. View, CA 94040)."
            : "To Process your Renewal membership application, we need the following documents to be filled out and returned either by email (secretary@sccpoa.org) or by US mail (SCCPOA, PO Box 4629, Mt. View, CA 94040)."}

          {/* Please fill out and send the "Membership Application
          Form", the "SCCPOA Range HHA", and a copy of your Law
          enforcement agency/department issued photo ID card (front
          and back side) to the address below or by email to:
          secretary@sccpoa.org. */}
        </p>
        <Row className={cs.confirmation_section}>
          <Col md={8}>
            <ul>
              <li className={cs.document_row}>
                <PDFLink
                  src="assets/pdf/RangeUseAgreement24.pdf"
                  text={
                    memberType === "new"
                      ? "Range HHA (pdf)"
                      : "Range HHA (Required)"
                  }
                />
              </li>
              <li className={cs.document_row}>
                <PDFLink
                  src={ApplicationForm}
                  text={
                    memberType === "new"
                      ? "Membership Application Form (pdf)"
                      : "*Update Contact Information (Optional)"
                  }
                />
              </li>
              {cartDetails.membershipDetails.description === "*Other" && (
                <li className={cs.document_row}>
                  <span className={cs.li_text}>
                    Proof of firearms Training (Post, California BSIS, ETC)
                  </span>
                </li>
              )}
              {memberType === "new" && (
                <li className={cs.document_row}>
                  <span className={cs.li_text}>
                    Clear and legible copy of your Law enforcement agency/
                    department issued photo ID card (front and back side)
                  </span>
                </li>
              )}
            </ul>
          </Col>
        </Row>
        {memberType === "renew" && (
          <div>
            *If your agency or contact information has changed in the past year,
            (ie. agency, name, address, phone number, or email), please fill out
            and send the "Update Contact Information" Form and a copy of your
            Law enforcement agency/department issued photo ID card (front and
            back side) to the address below or by email to:
            secretary@sccpoa.org.
          </div>
        )}
      </div>
    );
  }
}

export default RequiredDocuments;
