import BrochurePlayground from '../../../Common/assets/images/campground/Play structure.jpg'
import Vollyball from '../../../Common/assets/images/campground/vollyball.jpg'
import brickBBQ from '../../../Common/assets/images/campground/BBQ Pit Now.jpg'
import archery from '../../../Common/assets/images/campground/archeryKids.jpg'
import tentCabin from '../../../Common/assets/images/campground/Tent Cabin.jpg'
import horseShoe from '../../../Common/assets/images/campground/horseShoe.jpg'
import firepit from '../../../Common/assets/images/campground/firepit.jpg'
import shed from '../../../Common/assets/images/campground/shed.jpg'
import bathroom from '../../../Common/assets/images/campground/bathroom.png'



let campgroundInfo = {
	infoParagraphs:[
		"The Santa Clara County Peace Officers’ Association (S.C.C.P.O.A.) welcomes you to our private property and invites you to enjoy yourself amongst the 171 acres. Our property consists of three primary attractions, the campground, the pistol range, and the archery range. The campground has 8 tent cabins. You will be in the wilderness, hidden among the redwoods, above the City of Saratoga. The wildlife includes birds, deer, chipmunks, rabbits, raccoons, squirrels, and an occasional rattlesnake. Bobcat sightings and sightings of mountain lion tracks in the dirt occur but are rare.",
		"Our property is open <b>ONLY</b> to members and sponsored youth groups. <b>The second Saturday of each month is our WORK PARTY DAY. General use of the park is not recommended and the campground and range are closed.</b> Feel free to come up and lend a hand with maintenance and improvements.",
		"<b>No firearms or weapons may be discharged in the campground area, and paintball guns are strictly prohibited anywhere on the property. BB guns, pellet guns, and slingshots shall be fired only at the pistol range.</b>",
	],
	leftOfImgParagraphs:[
		"Please adhere to all use/safety rules at all and think of the consequences of your actions because contrary to popular belief, from time to time both the pistol range and the campgrounds suffer from what can reasonably be called “willful negligence.",
		"Any violations should be reported to the administrative offices / President Bud Smith, at (408) 264-1224"
	],
	campgroundFeatures: [
		{
			"img": BrochurePlayground,
			"title": "Playground",
			"body": "Within the campground, we have 2 children play structure areas, swings, and hiking trails."
		},
				{
			"img": Vollyball,
			"title": "Volleyball Court",
			"body": "Within the campground, we have a volleyball court and two basketball hoops."
		},
				{
			"img": brickBBQ,
			"title": "Brick Barbecue",
			"body": "We also have a large brick barbecue for group usage."
		},
				{
			"img": archery,
			"title": "Archery Range",
			"body": "Another area nestled above our campground is an archery range. There are two boxed targets with markings up to 25 yards. "
		},
				{
			"img": tentCabin,
			"title": "Tent Cabins",
			"body": "The SCCPOA campground features 8 camp sites, each with a canvas tent cabin (12’x16’) with wood floors, picnic tables, and barbecues."
		},
				{
			"img": horseShoe,
			"title": "Horse Shoe Pits",
			"body": "Within our facilities, you can enjoy playing horse shoes."
		},
				{
			"img": firepit,
			"title": "Campfire Amphitheater",
			"body": "There is a large circular cement firepit surrounded by seating for your enjoyment. No fires are permitted except for the firepit and barbecues. Make sure your fire is out before leaving it unattended, and honor any local fire warnings"
		},
				{
			"img": shed,
			"title": "Wooden Structures",
			"body": "Located within the campground are two wooden structures. The smaller of the two structures serves as an on-site office for the S.C.C.P.O.A., and the larger structure is a barn/tool shed with a fenced in equipment yard."
		},
		{
			"img": bathroom,
			"title": "Restrooms",
			"body": "Well water is provided for the bathrooms, but the other water outlets in the campground area's are chlorinated but not potable so bring water for drinking and cooking."
		
			// "body": "Our facilities maintain male and female restrooms (with hot and cold running water, flushing toilets, and showers).  Large groups need to bring additional bathroom supplies (soap, toilet paper, and paper towels). The water in the park is filtered and chlorinated but not potable so bring water for drinking and cooking. Please clean up after yourself. "
		},
	]

}


export default campgroundInfo;