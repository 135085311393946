import React from "react";
import cs from "./reactPayPal.module.css";

export default function ReactPayPal(props) {
  const [paid, setPaid] = React.useState(false);
  const [error, setError] = React.useState(null);
  const paypalRef = React.useRef();

  // To show PayPal buttons once the component loads
  React.useEffect(() => {
    window.paypal
      .Buttons({
        style: {
          shape: "pill",
          color: "gold",
          layout: "horizontal",
        },
        createOrder: (data, actions) => {
          console.log('calculateData', props.calculateTotal())
          return actions.order.create({
            intent: "CAPTURE",
            purchase_units: [
              {
                description: props.getPaymentDescription(),
                amount: {
                  currency_code: "USD",
                  //value: 100
                  value: toTwoDecimal(props.calculateTotal())
                },
              },
            ],
          });
        },
        onApprove: async (data, actions) => {
          const order = await actions.order.capture();
          setPaid(true);
        },
        onError: (err) => {
          //   setError(err),
          console.error(err);
        },
      })
      .render(paypalRef.current);
  }, []);

  // If the payment has been made
  if (paid) {
    return <div>Payment successful.!</div>;
  }

  // If any error occurs
  if (error) {
    return <div>Error Occurred in processing payment.! Please try again.</div>;
  }

  // let total = props.calculateTotal()
  // let subtotal = props.calculateSubtotal()
  // let paypalFees = Math.round((total-subtotal) * 100) / 100;

  // Default Render
  return (
    <div>
      <h4 className={cs.header}>
        PayPal/Credit Card + Paypal Fees ($
        {toTwoDecimal(props.calculateTotal())})
      </h4>
      <div ref={paypalRef} />
    </div>
  );
}

function toTwoDecimal(num) {
  return parseFloat(num.toString()).toFixed(2);
}