import React, {Component} from 'react';

import Header from '../../Common/Components/Header/header.jsx';
import Donations from '../../Common/Components/DonationPanel/donationPanel.jsx'
import Footer from '../../Common/Components/Footer/footer.jsx'
import PageTitle from '../../Common/Components/PageTitle/pageTitle.jsx'
import cs from './youthOutreach.module.css';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from 'react-bootstrap/Carousel';
import Emblems from '../../Common/assets/images/about/all agency.jpg';
import YouthOutreachGroupImg from '../../Common/assets/images/youthOutreachGroup.jpg';
import YouthGroupImg from '../../Common/assets/images/about/youthGroup.JPG';
import HeadingImg from '../../Common/assets/images/youthOutreach/headingImg.jpeg';
import accordionTopImg from '../../Common/assets/images/youthOutreach/accordionTopImg.jpg';
import accordionBottomImg1 from '../../Common/assets/images/youthOutreach/accordionBottomImg1.jpg';
import accordionBottomImg2 from '../../Common/assets/images/youthOutreach/accordionBottomImg2.jpg';
import History1 from '../../Common/assets/images/history/2.jpg';
import History2 from '../../Common/assets/images/history/3.jpg';
import History3 from '../../Common/assets/images/history/4.jpg';
import History4 from '../../Common/assets/images/history/5.jpg';
import History5 from '../../Common/assets/images/history/6.jpg';
import History6 from '../../Common/assets/images/history/7.jpg';
import History7 from '../../Common/assets/images/history/8.jpg';
import History8 from '../../Common/assets/images/history/9.jpg';
import History9 from '../../Common/assets/images/history/10.jpg';
import History10 from '../../Common/assets/images/history/11.jpg';
import History11 from '../../Common/assets/images/history/12.jpg';


import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'

import NewMemberMainImg from "../../Common/assets/images/newMemberMainImg.JPG"

class YouthOutreach extends Component {
  render(){
    return (
    	<div >
        <Header/>
        {/*<img class={cs.emblem_img} src={Emblems}/>*/}
        <Container>
          <PageTitle title="Youth Outreach"/>
            <img src={HeadingImg} className={cs.heading_img}></img>

            {/*<div className={cs.heading_text_wrapper}>
              <div className={cs.heading_wrapper}>
                <img src={HeadingImg} />
              </div>
              <div className={cs.text_wrapper}>SCCPOA</div>
            </div>
    */}
          <p>
            The SCCPOA Board Members organize and volunteer to work with our Campership Program, structured group outings with at-risk youth camping at our property. These campouts promote positive interaction between these at-risk youth and the law enforcement officers. This includes youngsters who may even have had a "brush" with the law, and may not necessarily have a positive feeling about law enforcement Officers from different agencies volunteer to "work" at the camp outs and this allows close contact between these youngsters and a law enforcement officer. 
          </p>
          <p>
              For the most part these youngsters come from our local satellite group homes. Chaperones from the group home involved are required to attend. The Girl scouts, Boy Scouts and cub scouts use the property on a regular basis. Additionally, our campground and property is open all year. 
          </p> 
          <br/>

          <div className={cs.tabs}>
          {/*
            
          */}

          <Accordion defaultActiveKey="1">
          <Card className={cs.card_boarder}>
              <Accordion.Toggle className={cs.toggle} as={Card.Header} eventKey="1"> Creating strong, positive relationships between our youth and local police officers! </Accordion.Toggle>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                <img src={accordionTopImg} className={cs.accordion_top_img}></img>
                <div className={cs.accordion_bottom_imgs}>
                  <div><img src={accordionBottomImg1}></img></div>
                  <div><img src={accordionBottomImg2}></img></div>
                </div>


                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card className={cs.card_boarder}>
              <Accordion.Toggle className={cs.toggle} as={Card.Header} eventKey="2"> Visited Youth Groups </Accordion.Toggle>
              <Accordion.Collapse eventKey="2">
                <Card.Body>
                  <p>
                    Boy Scout troop 163, 170, 230, 250, 340
                    <br/>
                    Scout Camp out BSA Troop 263
                    <br/>
                    Cub Scout pack 28, 163, 226, 234, 333, 340, 349, 360, 390, 419, 556
                    <br/>
                    American Heritage Girls troop CA0319
                    <br/>
                    Girl Scout Troop 61147, 61505, 62068
                    <br/>
                    Santa Clara PD - Daisy Troop Pack
                    <br/>
                    Santa Clara PD - Police Athletic League Judo Club Camp
                    <br/>
                    San Jose PD - Metro Team Building
                    <br/>
                    Mt. View - Crittenden Middle School WEB (Where Everyone Belongs) Anti-bullying mentorship program for 6th - 8th graders 
                    <br/>
                    Mt. View PD - Youth Services Campout
                    <br/>
                    Mt View PD - Explorer post, D.A.R.E.
                    <br/>
                    First Presbyterian Church.
                    <br/>
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card className={cs.card_boarder}>
              <Accordion.Toggle className={cs.toggle} as={Card.Header} eventKey="3"> Eagle Scout Projects </Accordion.Toggle>
              <Accordion.Collapse eventKey="3">
                <Card.Body>
                  <p>
                    <b>Service Project Examples:</b> picnic tables, work benches at the range, kiosks, picnic table storage boxes, and renovated hiking trails.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card className={cs.card_boarder}>
              <Accordion.Toggle className={cs.toggle} as={Card.Header} eventKey="4"> Charities sponsored by the SCCPOA </Accordion.Toggle>
              <Accordion.Collapse eventKey="4">
                <Card.Body>
                  <ul>
                    <li> The Salvation Army </li>
                    <li> City Team Ministries</li>
                    <li> Leukemia Society of America</li>
                    <li> Santa Clara County Council 4H</li>
                    <li> Palo Alto Bobby Sox</li>
                    <li> Special Olympics</li>
                    <li> Friends Outside</li>
                    <li> Santa Clara County Children's Shelter</li>
                    <li> Second Harvest Food bank</li>
                    <li> Ronald McDonald House</li>
                    <li> American Red Cross</li>
                    <li> Briarwood Little League</li>
                    <li> San Jose PAL program</li>
                    <li> Via Rehabilitation Services</li>
                    <li> Santa Clara Little League</li>
                    <li> Oakridge Little League</li>
                    <li> Lucille Packard Children's Hospital</li>
                  </ul>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card className={cs.card_boarder}>
              <Accordion.Toggle className={cs.toggle} as={Card.Header} eventKey="5"> Sponsored Scholarships </Accordion.Toggle>
              <Accordion.Collapse eventKey="5">
                <Card.Body>
                  <ul>
                    <li> West Valley College</li>
                    <li> Gavilan College</li>
                    <li> Evergreen College</li>
                    <li> Foothill College</li>
                    <li> San Jose City College</li>
                    <li> De Anza College</li>
                  </ul>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
          </div>
          <img class={cs.youth_group_img} src={YouthGroupImg} ></img>


        </Container>
        <Donations/>
        <br/>
        <Footer/>
    	</div>
    )
  }
}

export default YouthOutreach;