import React, {Component} from 'react';
import cs from './boardMembers.module.css';
import Header from '../../Common/Components/Header/header.jsx';
import Donations from '../../Common/Components/DonationPanel/donationPanel.jsx'
import Footer from '../../Common/Components/Footer/footer.jsx'
import PageTitle from '../../Common/Components/PageTitle/pageTitle.jsx'
import BoadOfDirectors from './components/boadOfDirectors'

import Container from 'react-bootstrap/Container';
import BoardMembersImg from '../../Common/assets/images/board members.jpg';

import TopBadges from "../../Common/assets/images/about/topBadges.jpg"
import BottomBadges from "../../Common/assets/images/about/bottomBadges.jpg"


class BoardMembers extends Component {
    render(){
        return (
        	<div>
        	    <Header/>
                <Container>
                    <PageTitle title="SCCPOA Board Members"/>
                    <p>
                        The SCCPOA Directors are devoted to protecting the rights and benefits of all SCCPOA Members. The following Board Member positions listed are elected or appointed as dictated in the by-laws of the Santa Clara County Peace Officers Association.
                    </p>
                    <img className={cs.img} src={BoardMembersImg} alt={"Board Members"}/>
                    <img className={cs.top_badge_img} src={TopBadges} alt={"Badges"}/>
                    <h3 align="center">Current Board of Directors</h3>
                    <BoadOfDirectors/>
                    <img align="center" className={cs.bottom_badge_img} src={BottomBadges} alt={"Badges"}/>
                    <hr/>
                    <h5>Interested in becoming a board member with the SCCPOA?  </h5>
                    <p>The SCCPOA allows 2 board members from each Agency within Santa Clara County.</p>
                    <ul>
                        <li>You must attend 3 consecutive board meetings to become a board member</li>
                        <li>Distribute fliers about the SCCPOA to your Agency</li>
                        <li>Membership and AD&D insurance provided at no charge</li>
                        <li>Free membership is included</li>
                    </ul>
                    <p>
                        If you are interested in being more involved with the SCCPOA please contact Bud Smith at <b>admin@sccpoa.org</b> for more details.
                    </p>
                </Container>
                <Donations/>
                <Footer/>
        	</div>
        )
    }
}

export default BoardMembers;

