import React, {Component} from 'react';
import Header from '../../Common/Components/Header/header.jsx';
import Donations from '../../Common/Components/DonationPanel/donationPanel.jsx'
import Footer from '../../Common/Components/Footer/footer.jsx'
import PageTitle from '../../Common/Components/PageTitle/pageTitle.jsx';
import PDFLink from '../../Common/Components/PDFLink/pdfLink.jsx';
import PropertyContactContainer from '../../Common/Components/PropertyContactContainer/propertyContactContainer';
import TopImage from '../../Common/assets/images/donations/topImage.JPG';
import BobyTaylor from '../../Common/assets/images/bobby taylor.jpg';
import cs from './range.module.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import DownRange from "../../Common/assets/images/downRange.jpg"
import RangeRules from "../../Common/assets/pdf/RangeRules.pdf"
//import RangeUseAgreement from "../../Common/assets/pdf/RangeUseAgreement24.pdf"

var data = require("./Data/range.js");

class Range extends Component {
  displayParagraphs(letterParagraphs){
    return (
      letterParagraphs.map(paragraph=>{
        return <p><div dangerouslySetInnerHTML={{__html: paragraph}}></div></p>
      })
    )
  }
  render(){
    return ( 
      <div>
        <Header/>
        <Container>
          <PageTitle title="Range Details"/>
          <img class={cs.top_img} src={DownRange}/>
           {this.displayParagraphs(data.rangeDetailsParagraphs)}
          
          <hr/>
          <Row>
            <Col sm={9}>
              <h3> Range Reservations </h3>
              <p>
                <a href="https://www.google.com/calendar/embed?src=hracing16%40gmail.com&ctz=America/Los_Angeles">
                  Please check the SCCPOA calendar for available dates. 
                  <span> <FontAwesomeIcon icon={'calendar-alt'} style={{color: "#337ab7"}} /></span>
                </a>
              </p>
              <p>
                Any member wishing to bring a guest must fill out/turn in a range HHA form before using the range. Please adhere to all posted use/safety rules at all times. Reservations for use of the pistol range are made on a first come-first serve basis. Reservations may be secured at any time by submitting a deposit according to the attached schedule.
              </p>
              <p>
                Send your completed forms / reservations by email to: <b>propertyscheduling@sccpoa.org</b> 
              </p>
              <p>
                Or by mail your completed forms / reservations to <b>P.O. Box 4629, Mountain View, Ca 94040</b>
              </p>
              <br/>
              <Row>
                <Col md={6}>
                  <PDFLink src="assets/pdf/RangeRules.pdf" text="Range Rules"/>
                </Col>
                <Col md={6}>
                  <PDFLink src="assets/pdf/RangeUseAgreement24.pdf" text="Range Use Hold Harmless Agreement"/>
                </Col>
              </Row>
              <br/>
              <p>
                If you would also like to make reservations for use of the campground, please visit our <a className={cs.make_blue} href="/campground"><b>Campground Information page.</b></a>
              </p>

            </Col>
            <Col sm={3}>
              <PropertyContactContainer/>
            </Col>
          </Row>
        </Container>
        <Donations/>
        <Footer/>
      </div>
    )
    
  }
}

export default Range;
