import React, { Component } from "react";
import BobbyTaylor from "../../assets/images/bobby taylor.jpg";
import cs from "./thankYouForSupportSection.module.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

var data = require("./data");

class DirectDonations extends Component {
  displayParagraphs(letterParagraphs) {
    return letterParagraphs.map((paragraph) => {
      return (
        <p>
          <div dangerouslySetInnerHTML={{ __html: paragraph }}></div>
        </p>
      );
    });
  }

  render() {
    let {whoDonationsHelpParagraphs } = data;
    return (
      <div>
        <hr/>
          <Row>
            <Col xs={12} lg={7} >
              <br/>
              <h3><b><i>Thank you for your support!</i></b></h3>
              {this.displayParagraphs(whoDonationsHelpParagraphs)}
            </Col>
            <Col xs={12} lg={5} >
              <img className={cs.img} src={BobbyTaylor} />
            </Col>
          </Row>



      </div>
    );
  }
}

export default DirectDonations;
