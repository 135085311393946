import React, {Component} from 'react';
import cs from './email.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class Email extends Component {
  render(){
    return (
      
         
	     <div className={cs.no_wrap}>
       {this.props.text}
       <a href={this.props.source}>
          <FontAwesomeIcon icon={'envelope'} />
          <span className={cs.email}>{this.props.email} </span>
        </a>

        </div>
	            
      
      
    )
  }
}

export default Email;
