module.exports = {
	panels: [
		{
			membershipAcronyn: "LER", 
			price: "120",
			membershipDetails: " Full-time sworn Peace Officers retired <b><i>within</i></b> Santa Clara County", 
			bodyTitle: "Law Enforcement Retired",
			payPalButtonValue: "VXJNRZS46F7JE",
			imgSrc: "assets/images/memberships/$75.jpg",
			numOfMemberships: 0
		},
		{
			membershipAcronyn: "LE", 
			price: "150",
			membershipDetails: "Full-time sworn Peace Officers actively working <b><i>within</i></b> Santa Clara County", 
			bodyTitle: "Law Enforcement",
			payPalButtonValue: "598Q4TS9A5HW8",
			imgSrc: "assets/images/memberships/$100.jpg",
			numOfMemberships: 0
		},
		{
			membershipAcronyn: "ASC LE", 
			price: "150",
			membershipDetails: "Full-time sworn Peace Officers active/retired working <b><i>outside</i></b> Santa Clara County", 
			bodyTitle: "Associate Law Enforcement",
			payPalButtonValue: "598Q4TS9A5HW8",
			imgSrc: "assets/images/memberships/$100.jpg",
			numOfMemberships: 0
		},
		{
			membershipAcronyn: "Other", 
			price: "200",
			membershipDetails: "ASC RES (Reserve Officer/Deputy), ASC CTS (Court Judge / DA / DDA)<b>", 
			bodyTitle: "Associate Member",
			payPalButtonValue: "RMBNN5R968A92",
			imgSrc: "assets/images/memberships/$150.jpg",
			numOfMemberships: 0
		},
		{
			membershipAcronyn: "*Other", 
			price: "200",
			membershipDetails: "ASC FED (Federal Agent, Special Agent, Officer), ASC COR (Deputy / Correctional Officer), ASC DPO (Deputy Probation Officer), ASC FIR (Fire Investigator) <br/><b><b>*</b>All of these categories require proof of firearms training. (Post, California BSIS, ETC)</b>", 
			bodyTitle: "Associate Member",
			payPalButtonValue: "RMBNN5R968A92",
			imgSrc: "assets/images/memberships/$150.jpg",
			numOfMemberships: 0
		}
	],
	paypalButtonIds: {
		ler: "",
		le: "",
		asc_le: "",
		other: "",
		ler_add: "",
		le_add: "",
		asc_le_add: "",
		other_add: ""
	}
}
