module.exports = {
  presidentName: "Bud C.Smith",
  letterTitle: "Summer Message",
  letterParagraphs: [
    "I would like to extend my sincere gratitude to the members of our association and our community for their continued support of our association. Your generous donations / contributions to our association, allows us the funds to keep the existing facility in good working order. It’s a never-ending task keeping the waterlines in good working order. A couple of projects that the property crew is currently working on, include replacing the stairs on several of the existing tent cabins, and replacing the 25-yard barricades at our range facility.",
    "Lots of positive feedback from both members and youth groups, about the hot water systems that were installed at both the campground BBQ pit area and at the range last year. The 120V electrical outlets at the range facility are now operational from 8 AM to 8PM.",
    "One Eagle Scout project is currently in the planning stages at this time. The plan is to add additional food storage lockers around tent cabins seven, eight and nine. The scout project should be completed by the end of this year.",
    "As it has been for over 53 years, the work party dates are on the 2nd Saturday of every month and on those days, the pistol range is CLOSED for maintenance. Come on up to help with the ongoing projects and you will be rewarded with a bbq’d lunch. Work party date or not, if there is a property improvement you want to do, with or without others, call us at 264-1224 so the project and supplies can be discussed.",
    "Also, be sure to check out our Facebook page, post a picture or a review of your day at our facility. https://www.facebook.com/SCCPOA/",
  ],
  letterValediction:
    "<br/>Until next time stay safe,<br/> <br/><i>Bud C. Smith</i> <br/>SCCPOA President",
};
