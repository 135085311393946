import React, {Component} from 'react';
import cs from '../campground.module.css';
import Col from 'react-bootstrap/Col';



class ImageBox extends Component {
  	render(){
  		let {img, title, body} = this.props

    	return (
    		<Col  xs={6} md={4} className={cs.img_cols}>
	      	<div className={cs.image_container}> <img class={cs.img} src={img} alt=""/> </div>
			    <h5 className={cs.center}> {title} </h5>
          <div className="Container" dangerouslySetInnerHTML={{__html: body}}></div>
	      </Col>
    	)
  	}
}

export default ImageBox;

