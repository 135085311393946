import React, { Component } from "react";
import ReactDOM from "react-dom";

import Header from "../../Common/Components/Header/header.jsx";
import Donations from "../../Common/Components/DonationPanel/donationPanel.jsx";
import Footer from "../../Common/Components/Footer/footer.jsx";
import PageTitle from "../../Common/Components/PageTitle/pageTitle.jsx";
import Email from "../../Common/Components/Email/email.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TopImage from "../../Common/assets/images/donations/topImage.JPG";
import BobyTaylor from "../../Common/assets/images/bobby taylor.jpg";
import cs from "./contact.module.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// const PayPalButton = paypal.Buttons.driver("react", { React, ReactDOM });

const Contact = () => {
  return (
    <div>
      {/* <PayPalButton
      createOrder={(data, actions) => this.createOrder(data, actions)}
      onApprove={(data, actions) => this.onApprove(data, actions)}
    /> */}
      <Header />
      <Container>
        <PageTitle title="Contact Us" />
        <Row>
          <Col md={5}>
            <h5>Mailing Address</h5>
            <p>
              Santa Clara County Peace Officers' Association
              <br />
              P.O. Box 4629
              <br />
              Mountain View, Ca 94040
              <br />
            </p>
            <p>
              <a href="https://www.google.com/calendar/embed?src=hracing16%40gmail.com&ctz=America/Los_Angelest">
                <FontAwesomeIcon
                  style={{ color: "#de5246" }}
                  icon={"calendar-alt"}
                />{" "}
                Google Calendar
              </a>
            </p>
            <p>
              <a href="https://www.facebook.com/SCCPOA/">
                <FontAwesomeIcon
                  icon={["fab", "facebook-f"]}
                  size="lg"
                  style={{ color: "#337ab7" }}
                />{" "}
                Facebook Page
              </a>
            </p>
          </Col>
          <Col md={7}>
            <Row>
              <Col lg={6} className={cs.questions_emails}>
                <div>
                  <b>Call Us At</b>
                </div>
                <FontAwesomeIcon icon={"phone-alt"} /> 408-264-1224
              </Col>
              <Col lg={6} className={cs.questions_emails}>
                <div>
                  <b>General Questions</b>
                </div>
                <div className={cs.email}>
                  <FontAwesomeIcon icon={"envelope"} />{" "}
                  <a href="mailto:admin@sccpoa.org">admin@sccpoa.org</a>
                </div>
              </Col>
              <Col lg={6} className={cs.questions_emails}>
                <div>
                  <b>Secretary</b>
                </div>
                <div className={cs.email}>
                  <FontAwesomeIcon icon={"envelope"} />{" "}
                  <a href="mailto:secretary@sccpoa.org">secretary@sccpoa.org</a>
                </div>
              </Col>
              <Col lg={6} className={cs.questions_emails}>
                <div>
                  <b>Property/Range Reservations</b>
                </div>
                <div className={cs.email}>
                  <FontAwesomeIcon icon={"envelope"} />{" "}
                  <a href="mailto:propertyscheduling@sccpoa.org">
                    propertyscheduling@sccpoa.org
                  </a>
                </div>
              </Col>
              <Col lg={6} className={cs.questions_emails}>
                <div>
                  <b>Membership Questions</b>
                </div>
                <div className={cs.email}>
                  <FontAwesomeIcon icon={"envelope"} />{" "}
                  <a href="mailto:membership@sccpoa.org">
                    membership@sccpoa.org
                  </a>
                </div>
              </Col>
              <Col lg={6} className={cs.questions_emails}>
                <div>
                  <b>Fundraising Questions</b>
                </div>
                <div className={cs.email}>
                  <FontAwesomeIcon icon={"envelope"} />{" "}
                  <a href="mailto:fundraising@sccpoa.org">
                    fundraising@sccpoa.org
                  </a>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Donations />
      <Footer />
    </div>
  );
};

// class Contact extends Component {
//   // createOrder(data, actions) {
//   //   return actions.order.create({
//   //     purchase_units: [
//   //       {
//   //         amount: {
//   //           value: "0.01",
//   //         },
//   //       },
//   //     ],
//   //   });
//   // }

//   // onApprove(data, actions) {
//   //   return actions.order.capture();
//   // }

//   render() {
//     return (
//       <div>
//         {/* <PayPalButton
//         createOrder={(data, actions) => this.createOrder(data, actions)}
//         onApprove={(data, actions) => this.onApprove(data, actions)}
//       /> */}
//         <Header />
//         <Container>
//           <PageTitle title="Contact Us" />
//           <Row>
//             <Col md={5}>
//               <h5>Mailing Address</h5>
//               <p>
//                 Santa Clara County Peace Officers' Association
//                 <br />
//                 P.O. Box 4629
//                 <br />
//                 Mountain View, Ca 94040
//                 <br />
//               </p>
//               <p>
//                 <a href="https://www.google.com/calendar/embed?src=hracing16%40gmail.com&ctz=America/Los_Angelest">
//                   <FontAwesomeIcon
//                     style={{ color: "#de5246" }}
//                     icon={"calendar-alt"}
//                   />{" "}
//                   Google Calendar
//                 </a>
//               </p>
//               <p>
//                 <a href="https://www.facebook.com/SCCPOA/">
//                   <FontAwesomeIcon
//                     icon={["fab", "facebook-f"]}
//                     size="lg"
//                     style={{ color: "#337ab7" }}
//                   />{" "}
//                   Facebook Page
//                 </a>
//               </p>
//             </Col>
//             <Col md={7}>
//               <Row>
//                 <Col lg={6} className={cs.questions_emails}>
//                   <div>
//                     <b>Call Us At</b>
//                   </div>
//                   <FontAwesomeIcon icon={"phone-alt"} /> 408-264-1224
//                 </Col>
//                 <Col lg={6} className={cs.questions_emails}>
//                   <div>
//                     <b>General Questions</b>
//                   </div>
//                   <div className={cs.email}>
//                     <FontAwesomeIcon icon={"envelope"} />{" "}
//                     <a href="mailto:admin@sccpoa.org">admin@sccpoa.org</a>
//                   </div>
//                 </Col>
//                 <Col lg={6} className={cs.questions_emails}>
//                   <div>
//                     <b>Secretary</b>
//                   </div>
//                   <div className={cs.email}>
//                     <FontAwesomeIcon icon={"envelope"} />{" "}
//                     <a href="mailto:secretary@sccpoa.org">
//                       secretary@sccpoa.org
//                     </a>
//                   </div>
//                 </Col>
//                 <Col lg={6} className={cs.questions_emails}>
//                   <div>
//                     <b>Property/Range Reservations</b>
//                   </div>
//                   <div className={cs.email}>
//                     <FontAwesomeIcon icon={"envelope"} />{" "}
//                     <a href="mailto:propertyscheduling@sccpoa.org">
//                       propertyscheduling@sccpoa.org
//                     </a>
//                   </div>
//                 </Col>
//                 <Col lg={6} className={cs.questions_emails}>
//                   <div>
//                     <b>Membership Questions</b>
//                   </div>
//                   <div className={cs.email}>
//                     <FontAwesomeIcon icon={"envelope"} />{" "}
//                     <a href="mailto:membership@sccpoa.org">
//                       membership@sccpoa.org
//                     </a>
//                   </div>
//                 </Col>
//                 <Col lg={6} className={cs.questions_emails}>
//                   <div>
//                     <b>Fundraising Questions</b>
//                   </div>
//                   <div className={cs.email}>
//                     <FontAwesomeIcon icon={"envelope"} />{" "}
//                     <a href="mailto:fundraising@sccpoa.org">
//                       fundraising@sccpoa.org
//                     </a>
//                   </div>
//                 </Col>
//               </Row>
//             </Col>
//           </Row>
//         </Container>
//         <Donations />
//         <Footer />
//       </div>
//     );
//   }
// }

export default Contact;
