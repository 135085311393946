import React, { Component } from "react";
import Header from "../../Common/Components/Header/header.jsx";
import Donations from "../../Common/Components/DonationPanel/donationPanel.jsx";
import Footer from "../../Common/Components/Footer/footer.jsx";
import PageTitle from "../../Common/Components/PageTitle/pageTitle.jsx";
import PDFLink from "../../Common/Components/PDFLink/pdfLink.jsx";
import DonateToday from "../../Common/Components/DonateToday/donateToday.jsx";
import TopImage from "../../Common/assets/images/donations/topImage.JPG";
import BobyTaylor from "../../Common/assets/images/bobby taylor.jpg";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import RaffleImg from "../../Common/assets/images/raffle/2023_raffle_flier.jpg";
import Pdf from "../../Common/assets/pdf/2023_raffle_flier.pdf";

class PdfQrCodeRedirect extends Component {
  componentDidMount() {
    if (typeof window !== "undefined") {
      window.location.href =
        "https://sccpoa.org/static/media/MASTER%20membership%20application.d35c8313.pdf";

      console.log("here");
    }
  }

  render() {
    return "test";
  }
}

export default PdfQrCodeRedirect;
