import React, { Component } from "react";
import Header from "../../Common/Components/Header/header.jsx";
import Donations from "../../Common/Components/DonationPanel/donationPanel.jsx";
import Footer from "../../Common/Components/Footer/footer.jsx";
import PageTitle from "../../Common/Components/PageTitle/pageTitle.jsx";
import cs from "./renewMemberships.module.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Membership from "../../Common/Components/Membership/membership.jsx";

class RenewMemberships extends Component {
  render() {
    return (
      <div>
        <Header />
        <Container>
          <PageTitle title="SCCPOA Renewal Memberships" />
          <Membership memberType={"renew"} />
          <Row className={cs.payments_container}>
            <Col sm={12} md={6}>
              <div>
                <b>Membership questions:</b>
              </div>
              <p>
                <FontAwesomeIcon icon={"envelope"} /> membership @sccpoa.org
              </p>
            </Col>
            <Col sm={12} md={6}>
              <div>
                <b>General questions:</b>
              </div>
              <p>
                <FontAwesomeIcon icon={"phone-alt"} /> Call the SCCPOA main
                office at 408-264-1224
              </p>
            </Col>
          </Row>
        </Container>
        <Donations />
        <Footer />
      </div>
    );
  }
}

export default RenewMemberships;
