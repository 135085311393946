import React, {Component} from 'react';

import cs from './campground.module.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Header from '../../Common/Components/Header/header.jsx';
import Donations from '../../Common/Components/DonationPanel/donationPanel.jsx'
import Footer from '../../Common/Components/Footer/footer.jsx'
import PageTitle from '../../Common/Components/PageTitle/pageTitle.jsx'
import PropertyContactContainer from '../../Common/Components/PropertyContactContainer/propertyContactContainer'

import MainCampground from '../../Common/assets/images/campground/mainCampground.jpg';

import ImageBox from './Components/imageBox.jsx'
import Button from '../../Common/Components/Button/button'
import data from "./Data/campground.js"

// var data = require("./Data/campground.js");

class Campground extends Component {
	displayParagraphs(paragraphData){
		return (
	      paragraphData.map(paragraph=>{
	        return <p><div dangerouslySetInnerHTML={{__html: paragraph}}></div></p>
	      })
	    )
	}

	render(){
  		console.log("data", data)
  		let {infoParagraphs, leftOfImgParagraphs, campgroundFeatures} = data
  
	    return (
	    	<div>
	    		<Header/>
	            <Container>
	            	<PageTitle title="Camping Information"/>
	            	<img className={cs.top_image} src={MainCampground}/>
				      	{this.displayParagraphs(infoParagraphs)}
	            		{/*<img className={cs.img} src="assets/images/campground/mainCampground.jpg"/>*/}

				      <Row>
				      	<Col xs={12} sm={6} md={8}>
						    {this.displayParagraphs(leftOfImgParagraphs)}
						    
							    <p>
							    	If you are interested in making a camping reservation, please click here: 
							    	<div className={cs.reservation_button}><Button text={"Reservation"} redirection={"/campgroundReservation"}/></div>
							   	</p>

				      	</Col>
				      	<Col xs={12} sm={6} md={4}> <PropertyContactContainer/> </Col>
				      </Row>
				      <Row>
				      	{campgroundFeatures.map(element=><ImageBox img={element.img} title={element.title} body={element.body}/>)}
				      </Row>
	            </Container>
	            <Donations/>
	            <Footer/>
	    	</div>
	    )
	 }
}

export default Campground;

