import React, { Component } from "react";
import Header from "../../Common/Components/Header/header.jsx";
import Donations from "../../Common/Components/DonationPanel/donationPanel.jsx";
import Footer from "../../Common/Components/Footer/footer.jsx";
import PageTitle from "../../Common/Components/PageTitle/pageTitle.jsx";
import DonateToday from "../../Common/Components/DonateToday/donateToday.jsx";

import cs from "./directDonations.module.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SCCPOFDirectDonationsImg from "../../Common/assets/images/charitableFoundation/SCCPOF_fundraising.jpg";
import PDFLink from "../../Common/Components/PDFLink/pdfLink.jsx";
import ThankYouForYourSupportSection from "../../Common/Components/ThankYouForSupportSection/thankYouForSupportSection.jsx"


class DirectDonations extends Component {
  displayParagraphs(letterParagraphs) {
    return letterParagraphs.map((paragraph) => {
      return (
        <p>
          <div dangerouslySetInnerHTML={{ __html: paragraph }}></div>
        </p>
      );
    });
  }

  render() {

    return (
      <div>
        <Header />
        <Container>
          <PageTitle title={this.props.title} />
          {/*<h3>
                      <b>Help Make a Difference in Our Community by supporting positive relationships between Bay Area Local Police Officers’ and Disadvantaged Youth!</b>
                    </h3>*/}
          {/* <img class={cs.img} src={TopImage}/>

                    <p className={cs.make_a_difference}>Help Make a Difference in Our Community by supporting positive relationships between Bay Area Local Police Officers’ and Disadvantaged Youth!</p>
                    {this.displayParagraphs(donationLetterParagraphs)} */}

          <p >
            <b>The Santa Clara County Peace Officers' Foundation</b>, founded 1965 have been working in our
            Bay Area communities. The men and women of the SCCPOF are dedicated to fostering positive relationships between police and youth in our community.
          </p>
          <p className={cs.paragraph_header}>SCCPOF Mission Statement</p>
          <p> 
            The Santa Clara County Peace Officers’ Foundation provides services to many organizations within Santa Clara County including underprivileged, disadvantaged, and/or at-risk youth in order to interact in a positive way with law enforcement officers while using our property. The purpose of the Santa Clara County Peace Officers' Foundation is to provide financial support for the SCCPOA property and projects. Funds for these projects are raised through member contributions, participation by members and the community in fund raising events, and public fundraiser campaigns.
          </p>
          <hr/>
          <p className={cs.paragraph_header}>SCCPOF Donations</p>
          <p>
            Your help is important. With your commitment to these programs, we can
            touch thousands of lives here in Santa Clara County, not just for a span of days
            or weeks, but for a lifetime. Please fill out and mail in the contribution card below.
          </p>

          <a href={"assets/pdf/charitableFoundation/SCCPOF_Fundraising.pdf"}>
            <img className={cs.img} src={SCCPOFDirectDonationsImg} />
          </a>
          <PDFLink
            src="assets/pdf/charitableFoundation/SCCPOF_Fundraising.pdf"
            text="Direct Donations"
          />
          <hr/>
          <div>
              <h3 align="left"> <b>SCCPOF Direct Donations Here!</b></h3>
              <p>
                <b>Donations should be sent by Paypal or by Check:</b>
              </p>
              <Row>
                <Col>
                    <p><b>Payments made by PayPal:</b></p>
                    <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
                    <input type="hidden" name="cmd" value="_s-xclick"/>
                    <input type="hidden" name="hosted_button_id" value="ZAM2QPXDBM9KY"/>
                    <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif" border="0" name="submit" alt="PayPal - The safer, easier way to pay online!"/>
                    <img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1"/>
                    </form>
                </Col>
                <Col>
                  <div>
                  <span className={cs.margin_bottom}><b>Payments made by check to:</b></span>  
                  <p >
                    Santa Clara County Peace Officers' Foundation 
                    <br/>
                    P.O. Box 4629 
                    <br/>
                    Mountain View, Ca 94040 
                    <br/>
                  </p>
                  </div>
                </Col>
              </Row>
              <br/>
              <p>
                Note: The SCCPOF is a tax-exempt organization under Section 501 c(3) of the Internal Revenue Code. This gift is considered tax-deductible as a charitable contribution for Federal Income Tax purposes. Federal Tax ID # 82-5444902.             
              </p>
              <p>
                Any charitable donation is 100% Tax Deductible! No funds received by the association are used outside the County. You may be able to deduct your donation as trade or business expenses, if ordinary and necessary in the conduct of the taxpayer’s business. Please consult your tax advisor as to how your support may be deducted.
              </p>
              <p>
                *Donation refund policy: Due to the nature of donations, refunds are not offered. Secure Checkout is provided through PayPal. 
              </p>
              <p>
                If you have any questions please email <b>fundraising@sccpoa.org</b>.
              </p>

          </div>

          <ThankYouForYourSupportSection/>
        </Container>
        <Donations />
        <Footer />
      </div>
    );
  }
}

export default DirectDonations;
