import React, {Component} from 'react';
import Header from '../../Common/Components/Header/header.jsx';
import Donations from '../../Common/Components/DonationPanel/donationPanel.jsx'
import Footer from '../../Common/Components/Footer/footer.jsx'
import PageTitle from '../../Common/Components/PageTitle/pageTitle.jsx'
import cs from './letterFromThePresident.module.css';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Letter from './Components/Letter.jsx'

var data = require("./Data/LetterFromThePresident.js");

class LetterFromThePresident extends Component {

  render(){
    return (
      <div>
        <Header/>
        <Container>
          <PageTitle title="Letter From The President"/>
          <Letter data={data}/>
        </Container>  
        <Donations/>
        <Footer/>
      </div>
    )
  }
}

export default LetterFromThePresident;
