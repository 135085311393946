import React, { Component } from "react";
import Header from "../../Common/Components/Header/header.jsx";
import Donations from "../../Common/Components/DonationPanel/donationPanel.jsx";
import Footer from "../../Common/Components/Footer/footer.jsx";
import PageTitle from "../../Common/Components/PageTitle/pageTitle.jsx";
import PDFLink from "../../Common/Components/PDFLink/pdfLink.jsx";
import DonateToday from "../../Common/Components/DonateToday/donateToday.jsx";
import TopImage from "../../Common/assets/images/donations/topImage.JPG";
import BobyTaylor from "../../Common/assets/images/bobby taylor.jpg";
import InsuranceBeneficiaryForm from "../../Common/assets/pdf/2023 remit card  3 x 5 card Invoice & AD&D Ins (Back side).pdf";
import InsuranceSummary from "../../Common/assets/pdf/AD&D insurance summary.pdf";
import cs from "./addInsurance.module.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import NewMemberMainImg from "../../Common/assets/images/newMemberMainImg.JPG";

class AddInsurance extends Component {
  render() {
    return (
      <div>
        <Header />
        <Container>
          <PageTitle title="AD&D Insurance" />
          <img
            className={cs.img}
            src={NewMemberMainImg}
            alt={"New Member Image"}
          />
          <h5 className={cs.step_header}>
            Optional - AD&D (Accidental Death and Dismemberment Insurance),
            Priced at $15/year:
          </h5>
          <p>
            Purchased Only Between October 1st – March 30th Annually. Optional
            insurance is valued at $15,000. If you wish to purchase optional
            AD&D insurance, you must fill out, sign, and mail the "AD&D
            Insurance Beneficiary Form" linked below. For more information
            please click on the "Insurance Summary" pdf form.
          </p>
          <Row className={cs.payments_container}>
            <Col sm={12} md={6}>
              <PDFLink
                src={InsuranceBeneficiaryForm}
                text="AD&D Insurance Beneficiary Form (pdf)"
              />
            </Col>
            <Col sm={12} md={6}>
              <PDFLink
                src={InsuranceSummary}
                text="AD&D Insurance Summary (pdf)"
              />
            </Col>
          </Row>

          <Row className={cs.payments_container}>
            <Col sm={12} md={6}>
              <div className={cs.payment_heading}>Payments made by PayPal:</div>
              <form
                action="https://www.paypal.com/cgi-bin/webscr"
                method="post"
                target="_top"
              >
                <input type="hidden" name="cmd" value="_s-xclick" />
                <input
                  type="hidden"
                  name="hosted_button_id"
                  value="X6YESRCM7E23L"
                />
                <input
                  type="image"
                  src="https://www.paypalobjects.com/en_US/i/btn/btn_paynowCC_LG.gif"
                  border="0"
                  name="submit"
                  alt="PayPal - The safer, easier way to pay online!"
                />
                <img
                  alt=""
                  border="0"
                  src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif"
                  width="1"
                  height="1"
                />
              </form>
            </Col>
            <Col sm={12} md={6}>
              <div className={cs.payment_heading}>
                Payments made by check to:
              </div>
              Santa Clara County Peace Officer's Association <br />
              P.O. Box 4629 <br />
              Mountain View, Ca 94040
            </Col>
          </Row>
        </Container>
        <Donations />
        <Footer />
      </div>
    );
  }
}

export default AddInsurance;
