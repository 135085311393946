import React, {Component} from 'react';
import cs from './button.module.css'

class Button extends Component {
  	render(){
  		let {text, redirection} = this.props

    	return (
     		<div>
				<a href={redirection} className={"btn btn-primary btn-lg " + cs.button} role="button" aria-pressed="true">{text}</a>
     		</div>
    	)
    
  	}
}

export default Button;




