import React, { Component } from "react";
import Tab from "react-bootstrap/Tab";
import Button from "react-bootstrap/Button";
import cs from "../membership.module.css";



class TabPane extends Component {

  render() {
    let {children, index, prevButtonLabel, nextButtonLabel, prevNextButtonClicked, nextButtonDisabled} = this.props

    return (
      <Tab.Pane eventKey={index}>
        {children}
        <div className={cs.next_prev_buttons_container}>
          {prevButtonLabel &&
            <Button
              className={cs.next_prev_buttons}
              onClick={prevNextButtonClicked.bind(this, index-1)}
            >
              Back To {" "} {prevButtonLabel}
            </Button>
          }
          {nextButtonLabel &&
            <Button
              className={cs.next_prev_buttons}
              onClick={prevNextButtonClicked.bind(this, index+1)}
              disabled={nextButtonDisabled}
            >
              Continue To {" "} {nextButtonLabel}
            </Button>
          }
        </div>
      </Tab.Pane>
    );
  }
}

export default TabPane;




