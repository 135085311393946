import React, { Component } from "react";
import Header from "../../Common/Components/Header/header.jsx";
import Donations from "../../Common/Components/DonationPanel/donationPanel.jsx";
import Footer from "../../Common/Components/Footer/footer.jsx";
import PageTitle from "../../Common/Components/PageTitle/pageTitle.jsx";
import DonateToday from "../../Common/Components/DonateToday/donateToday.jsx";
import cs from "./roadConditions.module.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Card from "react-bootstrap/Card";
import RoadMaintenance from "../../Common/assets/images/road/roadMaintenance.JPG";
import HW9 from "../../Common/assets/images/road/hw9.jpg";

var data = require("./Data/roadConditions.js");

class RoadConditions extends Component {
  displayParagraphs(letterParagraphs) {
    return letterParagraphs.map((paragraph) => {
      return (
        <p>
          <div dangerouslySetInnerHTML={{ __html: paragraph }}></div>
        </p>
      );
    });
  }

  render() {
    let { roadConditionsParagraphs } = data;
    return (
      <div>
        <Header />
        <Container>
          <PageTitle title="Road Conditions" />

          <div className={cs.road_status}>
            <div>Current Road Status: Road Open</div>
            <div>Updated: 5/5/23</div>
          </div>

          {/* <Row className={cs.row}>
            <Col sm={12} className={cs.about_columns}>
              <Card className={cs.about_container}>
                <Card.Header className={cs.card_header_warning}>
                  <h3>Road Notice</h3>{" "}
                  <p>
                    <b>
                      The property and range road is open for all vehicles at
                      this time.
                    </b>
                  </p>{" "}
                  <p>
                    <b>Updated: 3/30/2023</b>
                  </p>
                </Card.Header>
              </Card>
            </Col>
          </Row>

          <hr />
          <h2>Notice of Highway 9 Saratoga Creek Bridge Rehabilitation Work</h2>
          <br />
          <h4>Proposed Lane Reductions:</h4>
          <p>
            There will be mainly full time one-way traffic control Monday
            through Friday. At specific announced periods, there will need to be
            a full closure and detours will be established.
          </p>
          <br />
          <h2>Highway 9 Project Detours</h2>
          <br />
          <h4>State Route 9 Northbound Detour:</h4>

          <ul>
            <li>Turn RIGHT/LEFT on Redwood Gulch Rd</li>
            <li>Turn RIGHT on Stevens Canyon Rd</li>
            <li>Turn RIGHT on Mt. Eden Rd</li>
            <li>Turn RIGHT on Pierce Rd</li>
            <li>Turn RIGHT/LEFT on NB SR-9</li>
          </ul>

          <br />
          <h4>State Route 9 Southbound Detour:</h4>

          <ul>
            <li>Turn RIGHT/LEFT on Pierce Rd</li>
            <li>Turn LEFT on Mt. Eden Rd</li>
            <li>Turn LEFT on Stevens Canyon Rd</li>
            <li>Turn LEFT on Redwood Gulch Rd</li>
            <li>Turn RIGHT/LEFT on SB SR-9</li>
          </ul>
          <br />
          <img className={cs.img} src={HW9} />
          <br />
          <a href="https://dot.ca.gov/caltrans-near-me/district-4/d4-projects/d4-santa-clara-sr9-saratoga-creek-bridge">
            https://dot.ca.gov/caltrans-near-me/district-4/d4-projects/d4-santa-clara-sr9-saratoga-creek-bridge
          </a>
          <br />
          <br />
    */}
          <img className={cs.img} src={RoadMaintenance} />

          <Row>
            <Col xs={12}>
              {this.displayParagraphs(roadConditionsParagraphs)}
              <p>
                <b>
                  <i>
                    The Santa Clara County Peace Officers' Association
                    appreciates your support!
                  </i>
                </b>
              </p>
              {/*
			            <div class="col-lg-12">
			                <object data="assets/pdf/roadMaintenanceFundFlier.pdf" type="application/pdf" width="100%" height="400em">
			                    <p> This browser does not support PDFs. Please download the PDF to view it: <a href="assets/pdf/roadMaintenanceFundFlier.pdf">Download PDF</a>.</p>
			                </object>
			            </div>
			        */}
            </Col>
          </Row>
          <Row style={{ paddingBottom: "10px" }}>
            <Col md={4}>
              <a href="assets/pdf/roadMaintenanceFundFlier.pdf">
                <div class="media">
                  <div class="pull-left"></div>
                  <span class="fa-stack">
                    <FontAwesomeIcon
                      icon={["far", "file-pdf"]}
                      style={{ color: "#337ab7" }}
                      size="2x"
                    />
                  </span>
                  Road Maintenance Donation Flier
                </div>
              </a>
            </Col>
          </Row>
          <hr />
          <DonateToday />
          {/*<img className={cs.img} src="assets/images/road/roadMaintenance.jpg"/>*/}
          {
            //       <p>
            //           <b>You can either make a donation by PayPal using the button below or by check to the address below:</b>
            //       </p>
            // <table style={{width:"100%"}}>
            //           <tr>
            //             <th>Payments made by PayPal:</th>
            //             <th>Payments made by check to:</th>
            //           </tr>
            //           <tr>
            //             	<td>
            //                <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
            //                 <input type="hidden" name="cmd" value="_s-xclick"/>
            //                 <input type="hidden" name="hosted_button_id" value="7VDFM7LYQMVHN"/>
            //                 <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif"
            //                 	   border="0" name="submit" alt="PayPal - The safer, easier way to pay online!"/>
            //                 <img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1"/>
            //                </form>
            //               </td>
            //               <td>
            //                   Santa Clara County Peace Officer's Association
            //                   <br/>
            //                   P.O. Box 4629
            //                   <br/>
            //                   Mountain View, Ca 94040
            //                   <br/>
            //               </td>
            //           </tr>
            //       </table>
          }
        </Container>
        <Donations />
        <Footer />
      </div>
    );
  }
}

export default RoadConditions;
