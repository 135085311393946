import React, { Component } from "react";
import cs from "../membership.module.css";
import Receipt from "./receipt.jsx";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ReactPayPal from "../../paypal/ReactPayPal.jsx";


class Checkout extends Component {
    constructor(props) {
    super(props);
    this.state = {
    }
    this.getPaymentDescription = this.getPaymentDescription.bind(this);
  }

  getPaymentDescription() {
    let { membershipDetails, addDetails, raffleTicketDetails, donationAmount } = this.props.cartDetails;
    let newOrRenew = this.props.memberType == "new" ? "New Membership" : "Renewal Membership";
    let membershipDescription = newOrRenew + ", " + membershipDetails.description + " ($" + membershipDetails.price + ")";
    let addDescription = "";
    let raffleTicketDescription = "";
    let donationDescription = "";

    if (addDetails.isSelected)
      addDescription = ", AD&D Insurance ($" + addDetails.price + ")";
    if (!!raffleTicketDetails.totalTickets)
      raffleTicketDescription = ", " + raffleTicketDetails.totalTickets + " Raffle Tickets ($" + raffleTicketDetails.price * raffleTicketDetails.totalTickets + ")";
    if (!!donationAmount)
      donationDescription = ", Donation ($" + donationAmount + ")";

    return (
      membershipDescription +
      addDescription +
      raffleTicketDescription +
      donationDescription
    );
  }

  render() {
    let {cartDetails, calculateTotal, calculatePaypalTotalWithTax, toTwoDecimal} = this.props

    return (
      <div>
        <span className={cs.numbered_info}>
          <h2 className={cs.step_header}>Checkout</h2>
          <Receipt
            cartDetails={cartDetails}
            subtotal={calculateTotal()}
            toTwoDecimal={toTwoDecimal}
          />

          <div>
            <h4 className={cs.payment_total}>Payment</h4>
            <Row>
              <Col>
                <ReactPayPal
                  className={cs.deactivated}
                  calculateTotal={() => calculatePaypalTotalWithTax( calculateTotal() )}
                  getPaymentDescription={this.getPaymentDescription}
                  toTwoDecimal={toTwoDecimal}
                  // calculateSubtotal={this.calculateTotal}
                />
              </Col>
              <Col>
                <h4 className={cs.header}>
                  If paid by check ($
                  {toTwoDecimal( calculateTotal() )})
                </h4>
                <h4 className={cs.header}>Remit payment to:</h4>
                <p className={cs.check_content}>
                  Santa Clara County Peace Officer's Association
                  <br />
                  P.O. Box 4629
                  <br />
                  Mountain View, Ca 94040
                </p>
              </Col>
            </Row>
          </div>

          <br />
          <p>
            <b> SCCPOA Membership Refund/Cancellation Policy </b>{" "}
            membership dues are non-refundable and memberships are
            non-transferable. The Santa Clara County Peace Officers
            Association reserves the right to refuse/cancel a membership
            in the SCCPOA. If SCCPOA refuses a new or renewing
            membership, registrants will be offered a refund.{" "}
            <b> Information Collected:</b> Our website does not
            automatically collect any individually identifiable
            information.{" "}
            <b>
              Your privacy is important to us, and we are committed to
              protecting it. We will never disclose or share your
              personal information without your express written consent.
            </b>{" "}
            Secure Checkout is provided through PayPal. PayPal uses the
            latest in data encryption and anti-fraud technology to keep
            your information secure, reducing the risk of online fraud.
          </p>
          {
            // this.state.currentSelectedMemberType && (
            //   // <ReactPayPal total={1} />
            //   <ReactPayPal
            //     className={cs.deactivated}
            //     total={this.state.totalValue}
            //   />
            // )
          }
        </span>
       
      </div>
    )
  }
}

export default Checkout;




