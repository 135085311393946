import React, {Component} from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import cs from '../letterFromThePresident.module.css';
import PresidentImage from "../../../Common/assets/images/youngBud.jpg"

class Letter extends Component {

  displayParagraphs(letterParagraphs){
    return (
      letterParagraphs.map((paragraph, i)=>{
        return <p key={i} dangerouslySetInnerHTML={{__html: paragraph}}></p>
      })
    )
  }
  render(){
  	let {presidentName, letterTitle, letterParagraphs, letterValediction} = this.props.data
    return (
    	<div>
        <Row>
          <Col md={3}>

            <Col xs={6} md={12}>
              <img className={cs.img} src={PresidentImage} alt={"President"}/>
            </Col>
            <Col xs={6} md={12}>
              <h4 className={cs.president}>President</h4>
              <p style={{height: 'auto', fontSize: '16px', fontWeight:'bold', fontFamily: "'Alegreya Sans', sans-serif; font-style: italic"}} > 
                <i>{presidentName}</i>
              </p>
            </Col>

          </Col>
          <Col md={9}>

            <h3 className={cs.letter_title}> {letterTitle} </h3>
            <div className={cs.paragraph_container}>
              {this.displayParagraphs(letterParagraphs)}
            </div>
            <p dangerouslySetInnerHTML={{__html: letterValediction}}></p>
  
          </Col>
        </Row>
      </div>
  	)
	}
}

export default Letter;

