import React, { Component } from "react";
import cs from "../membership.module.css";

class Receipt extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  calculatePaypalFees(total) {
    let totalFees = total * 0.0349 + 0.49;
    return Math.round(totalFees * 100) / 100;
  }
  calculatePaypalTotalWithTax(total) {
    let totalAmount = total * 1.0349 + 0.49;
    return Math.round(totalAmount * 100) / 100;
  }

  render() {
    let {
      cartDetails: {
        membershipDetails,
        addDetails,
        // raffleTicketDetails,
        donationAmount,
      },
      subtotal,
      toTwoDecimal,
      addInsuranceAvailable
    } = this.props;

    return (
      <div className={cs.receipt_container}>
        <div className={cs.receipt}>
          <div className={cs.receipt_col}>
            <div className={cs.receipt_header}> Description </div>
            <div className={cs.receipt_data}>
              {" "}
              {membershipDetails.description}{" "}
            </div>
            { addInsuranceAvailable && <div className={cs.receipt_data}> AD&D Insurance </div>} 
            {/*<div className={cs.receipt_data}> Raffle Tickets </div>*/}
            <div className={cs.receipt_data}> Donations </div>
          </div>

          <div className={cs.receipt_col}>
            <div className={cs.receipt_header}> Qty </div>
            <div className={cs.receipt_data}> 1 </div>
            { addInsuranceAvailable && 
              <div className={cs.receipt_data}>
                {" "}
                {addDetails.isSelected ? 1 : 0}{" "}
              </div> 
            }
            {/*<div className={cs.receipt_data}>
              {" "}
              {raffleTicketDetails.totalTickets}{" "}
    </div>*/}

            <div className={cs.receipt_data}> {!!donationAmount ? 1 : 0} </div>
          </div>

          <div className={cs.receipt_col}>
            <div className={cs.receipt_header}> Price </div>
            <div className={cs.receipt_data}>
              {" "}
              ${toTwoDecimal(membershipDetails.price)}{" "}
            </div>
            { addInsuranceAvailable && <div className={cs.receipt_data}> $15.00 </div> }
            {/*<div className={cs.receipt_data}>
              {" "}
              ${toTwoDecimal(raffleTicketDetails.price)}{" "}
  </div>*/}
            <div className={cs.receipt_data}>
              {" "}
              ${toTwoDecimal(donationAmount || 0)}{" "}
            </div>
          </div>

          <div className={cs.receipt_total}>
            <div className={cs.receipt_header}> Total </div>
            <div className={cs.receipt_data}>
              {" "}
              ${toTwoDecimal(1 * membershipDetails.price)}{" "}
            </div>
            { addInsuranceAvailable &&
              <div className={cs.receipt_data}>
                {" "}
                $
                { toTwoDecimal(
                  (addDetails.isSelected ? 1 : 0) * addDetails.price
                )}{" "}
              </div> 
            }
            {/*<div className={cs.receipt_data}>
              {" "}
              $
              {toTwoDecimal(
                raffleTicketDetails.totalTickets * raffleTicketDetails.price
              )}{" "}
              </div>*/}
            <div className={cs.receipt_data}>
              {" "}
              ${toTwoDecimal(donationAmount) || 0}{" "}
            </div>
          </div>
        </div>

        <div className={cs.receipt_subtotal}>
          {/*<div>
            <div className={cs.receipt_data}> Subtotal </div>
            <div className={cs.receipt_data}> Paypal Fees </div>
            <div className={cs.receipt_data}> Total </div>
          </div>*/}
          <div>
            <div className={cs.receipt_data}> ${toTwoDecimal(subtotal)} </div>
            {/*<div className={cs.receipt_data}> ${this.calculatePaypalFees(subtotal)} </div>
            <div className={cs.receipt_data}> ${this.calculatePaypalTotalWithTax(subtotal)} </div>*/}
          </div>
        </div>
      </div>
    );
  }
}

export default Receipt;
