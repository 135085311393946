import React, { Component } from "react";
import cs from "./memberTypePanel.module.css";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";

class MemberTypePanel extends Component {
  constructor(props) {
    super(props);

    this.onCardClick = this.onCardClick.bind(this);
  }

  onCardClick() {
    let {
      handleMemberTypeClick,
      data: { membershipAcronyn, price },
    } = this.props;
    if (handleMemberTypeClick)
      handleMemberTypeClick(membershipAcronyn, parseInt(price));
  }

  render() {
    let {
      membershipAcronyn,
      membershipDetails,
      bodyTitle,
      payPalButtonValue,
      price,
      numOfMemberships,
    } = this.props.data;
    let {
      withPaypal,
      currentSelectedMemberType,
      handleMemberTypeClick,
      membershipNumChanged,
      idx,
    } = this.props;

    let cardClass = "";
    if (!currentSelectedMemberType) {
      cardClass = "";
    } else if (currentSelectedMemberType === membershipAcronyn) {
      cardClass = cs.selected_member_card;
    } else {
      cardClass = cs.notSelected_member_card;
    }

    return (
      <div
        className={cs.panel_container + " " + cardClass}
        onClick={this.onCardClick}
      >
        <div className={cs.member_description}>
          <div className={cs.header}>
            {" "}
            {bodyTitle + " (" + membershipAcronyn + ")"}{" "}
          </div>
          <div
            className={cs.membership_info}
            dangerouslySetInnerHTML={{ __html: membershipDetails }}
          ></div>
        </div>
        <div className={cs.price}>
          ${price}
          <div className={cs.perYear}> per year </div>
        </div>
        {/*
        <div className={cs.membership_number}>
          <input
            type="number"
            step="1"
            className={cs.membership_number_input}
            value={numOfMemberships}
            onChange={membershipNumChanged.bind(this, idx)}
          />
        </div>
        */}
      </div>
    );

    return (
      <Card className={cs.card + " " + cardClass} onClick={this.onCardClick}>
        <Card.Header className={cs.header}>{membershipAcronyn}</Card.Header>
        <div className={cs.price}>
          ${price}
          <div className={cs.perYear}> per year </div>
        </div>

        <div variant="flush" className={cs.membership_container}>
          <div className={cs.membership_data_container}>
            <div className={cs.membership_type}>
              {" "}
              <b>{bodyTitle}</b>{" "}
            </div>
            <div
              className={cs.membership_info}
              dangerouslySetInnerHTML={{ __html: membershipDetails }}
            ></div>
          </div>
        </div>
      </Card>
    );
  }
}

export default MemberTypePanel;
