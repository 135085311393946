import React, {Component} from 'react';
import Carousel from 'react-bootstrap/Carousel';
import cs from './carousel.module.css'

class CarouselComponent extends Component {
  	render(){
  		let {carouselImages} = this.props

    	return (
      		<Carousel className={cs.carousel}>
      			{
      				carouselImages.map(image=>
      					<Carousel.Item>
		              		<div className={cs.image_container}>
		                		<img className={cs.image} src={image} alt="First slide"/>
		              		</div>
		              	</Carousel.Item>      
              		)
      			}
            </Carousel>
    	)
  	}
}

export default CarouselComponent;




