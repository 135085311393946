import React, { Component } from "react";
import cs from "./pdfLink.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import test from "../../assets/images/poa_logo.png";

// const PDFLink = (props) => {
//   return (
//     <a
//       href={this.props.src}
//       target="_blank"
//       rel={test}
//       className={cs.summary_pdf}
//     >
//       <span className="media">
//         <span className="fa-stack">
//           <FontAwesomeIcon
//             icon={["far", "file-pdf"]}
//             style={{ color: "#337ab7" }}
//             size="2x"
//           />
//         </span>
//         {this.props.text}
//       </span>
//     </a>
//   );
// };

class PDFLink extends Component {
  render() {
    return (
      <a
        href={this.props.src}
        target="_blank"
        rel={test}
        className={cs.summary_pdf}
      >
        <span className="media">
          <span className="fa-stack">
            <FontAwesomeIcon
              icon={["far", "file-pdf"]}
              style={{ color: "#337ab7" }}
              size="2x"
            />
          </span>
          {this.props.text}
        </span>
      </a>
    );
  }
}
export default PDFLink;
