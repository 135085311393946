import React, { Component } from "react";
import Card from "react-bootstrap/Card";

import ListGroup from "react-bootstrap/ListGroup";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Header from "../../Common/Components/Header/header.jsx";
import PopUp from "../../Common/Components/PopUp/popUp.jsx";
import Donations from "../../Common/Components/DonationPanel/donationPanel.jsx";
import Footer from "../../Common/Components/Footer/footer.jsx";
import PageTitle from "../../Common/Components/PageTitle/pageTitle.jsx";
import cs from "./home.module.css";
import CarouselComponent from "../../Common/Components/Carousel/carousel";
import BusinessSupporters from "../../Common/Components/BusinessSupporters/businessSupporters.jsx";

import BoardOfDirectorsImg from "../../Common/assets/images/boardOfDirectors2.jpg";
import MainPresidentMessageImg from "../../Common/assets/images/mainPresidentMessage.jpg";
import AboutImg from "../../Common/assets/images/home/about.jpg";
import MembershipsImg from "../../Common/assets/images/home/memberships.jpg";
import DirectionsImg from "../../Common/assets/images/directions.JPG";
import MainCabinImg from "../../Common/assets/images/mainCabin.jpg";
import MainPistolRangeImg from "../../Common/assets/images/mainPistolRange.png";
import MainRoadImg from "../../Common/assets/images/mainRoad2.jpg";
import CardComponent from "../../Common/Components/Card/card";

import Welcome1 from "../../Common/assets/images/home/welcome1.png";
import Welcome2 from "../../Common/assets/images/home/welcome2.png";
import Welcome3 from "../../Common/assets/images/home/welcome2.png";
import Welcome4 from "../../Common/assets/images/home/welcome4.png";

import CampgroundHome from "../../Common/assets/images/campground_home.jpg";
import About from "../../Common/assets/images/home/about.jpg";
import MainCampgroundImg from "../../Common/assets/images/campground/mainCampground.jpg";
import DownRangeImg from "../../Common/assets/images/downRange.jpg";
import CampgroundImg from "../../Common/assets/images/home/campground.jpg";
import YouthGroupImg from "../../Common/assets/images/home/youthGroup.JPG";
import YouthOutreachImg from "../../Common/assets/images/youthOutreach.jpg";
import ArcheryImg from "../../Common/assets/images/archery 1.jpg";
import SnowmanImg from "../../Common/assets/images/snowman.JPG";

import Membership from "../../Common/assets/images/home/membership.jpg";

import PBM from "../../Common/assets/images/businessSupporters/PBM.png";
import FriendsOfNRA from "../../Common/assets/images/businessSupporters/friendsOfNRA.png";
import five11 from "../../Common/assets/images/businessSupporters/511.png";

import WelcomeBackground from "../../Common/assets/images/home/campground.jpg";
import SccpoaBadge from "../../Common/assets/images/home/poa_logo.png";
import SccpofBadge from "../../Common/assets/images/home/SCCPOF _Logo.png";
import FundraisingKids from "../../Common/assets/images/home/fundraising_home_img.jpg";
import Button from '../../Common/Components/Button/button'
import BobbyTaylor from "../../Common/assets/images/campground/MVPD.jpeg"


class Home extends Component {
  constructor(props) {
    super(props);
    this.state = { showPopup: true };
  }

  togglePopup() {
    this.setState({
      showPopup: !this.state.showPopup,
    });
  }

  render() {
    // let carouselImages = [Welcome, MainCampgroundImg, DownRangeImg, CampgroundImg, YouthGroupImg, YouthOutreachImg, ArcheryImg, SnowmanImg]
    let businessSupporters = [
      { img: FriendsOfNRA, href: "https://www.friendsofnra.org/" },
      { img: PBM, href: "http://www.pbm1923.com/" },
      { img: five11, href: "https://www.511tactical.com/" },
    ];
    const welcomeBackgroundStyle = {
      backgroundImage: `url(${WelcomeBackground})`,
    };

    return (
      <div>
        <Header />
        <Container>
          {/*<CarouselComponent carouselImages={carouselImages}/>*/}
          <img className={cs.top_image} src={Welcome4} />

          {/* Dynamic Welcome Image
                        <div className={cs.welcome_container} >
                            <div><img className={cs.badge} src={SccpoaBadge}/></div>
                            <div className={cs.welcome_text}>
                                Welcome to the Santa Clara County Peace Officers' Association
                            </div>
                            <div><img className={cs.badge} src={SccpofBadge}/></div>
                        </div>
                    */}
          {/*<h1 className="text-center"> Welcome To The SCCPOA</h1>*/}
          {/*<Row className={cs.row}>
            <Col sm={12} className={cs.about_columns}>
              <Card className={cs.about_container}>
                <Card.Header className={cs.card_header_warning}>
                  <h3>Road Notice</h3>{" "}
                  <p>
                    <b>
                      The property and range road is open for all vehicles at
                      this time.
                    </b>
                  </p>
                  <p>
                    <b>
                      Highway 9 / Road Closure Notice. For more details,{" "}
                      <a href="/roadConditions">Click here</a>
                    </b>
                  </p>{" "}
                  <p>
                    <b>Updated: 3/12/2023</b>
                  </p>
                </Card.Header>
              </Card>
            </Col>
                  </Row>*/}
          {/*<Row className={cs.row}>
            <Col sm={12} className={cs.about_columns}>
              <Card className={cs.about_container}>
                <Card.Header className={cs.card_header_warning}>
                  <h3>Road Work Notice</h3>{" "}
                  <p>
                    <b>Expect intermittent delays through October 15th</b>
                  </p>{" "}
                  <b>
                    Road closed October 16th due to heavy machinery applying
                    sticky oil and loose rock on the roadway
                  </b>
                </Card.Header>
              </Card>
            </Col>
          </Row>
          <Row className={cs.row}>
             <Col sm={12} className={cs.about_columns}>
                 <Card className={cs.about_container}>
                     <Card.Header className={cs.card_header_warning}><h3>Heavy Machinery Road Work On October 14th & 15th</h3> <p><b>Recommended no usage on the dates above due to sticky oil and loose rock placed on the roadway</b></p></Card.Header>
                 </Card>
             </Col>
         </Row>*/}
          {/* <Row className={cs.row}>
            <Col sm={12} className={cs.about_columns}>
              <Card className={cs.about_container}> 
                <Card.Header className={cs.card_header_warning}>
                  <h3>Road Work Notice</h3>
                  <p>SCCPOA Roads from June 21 - 23 will be CLOSED</p>
                  (Heavy machinery road work, sticky oil and loose gravel to be
                  placed on the roadway)
                </Card.Header>
              </Card>
            </Col>
          </Row> */}

          {/* <Row className={cs.row}>
            <Col sm={12} className={cs.about_columns}>
              <Card className={cs.about_container}>
                <Card.Header className={cs.card_header}>About Us</Card.Header>
                <Card.Text className={cs.about_body}>
                  <Row className={cs.badge_row}>
                    <Col md={2} className={cs.badge_container}>
                      <img className={cs.badge} src={SccpoaBadge} />
                    </Col>
                    <Col md={10} className={cs.about_text}>
                      <a href="about">
                        <b>The Santa Clara County Peace Officers’ Association</b>
                      </a>
                      {" "}is a non-profit 501(c)(4) and provides services to many
                      organizations within Santa Clara County including
                      underprivileged, disadvantaged, and/or at-risk youth in
                      order to interact in a positive way with law enforcement
                      officers while using our property.
                    </Col>
                  </Row>

                  <Row className={cs.badge_row}>
                    <Col md={2} className={cs.badge_container}>
                      <img className={cs.badge} src={SccpofBadge} />
                    </Col>
                    <Col md={10} className={cs.about_text}>
                      <a href="charitableFoundation">
                        <b>The Santa Clara County Peace Officers’ Foundation</b>
                      </a>
                      {" "}is a non-profit 501(c)(3). The Foundation provides
                      financial support for the SCCPOA property and projects.
                    </Col>
                  </Row>
                </Card.Text>
              </Card>
            </Col>
         
          </Row> 
          */}

          <hr />
          <Row className={cs.row}>
            <Col sm={12} className={cs.about_columns}>
              <Card className={cs.about_container}>
                <Card.Header className={cs.card_header}>Annual Fund-raising is Underway</Card.Header>
                <Card.Text className={cs.about_body}>
                  <div>
                    <img className={cs.fundraising_img} src={FundraisingKids} />
                  </div>
                  <Row className={cs.fundraising_text_container}>
                    <Col md={8} className={cs.fundraising_text} >
                      <p>It is time for our annual fund-raising drive.  We will be reaching out to Bay area residents and businesses in the coming weeks to raise money for local, worthy causes.</p>
                      <p>Although SCCPOA has been raising money this way for 52 years, there are always reasonable concerns that these solicitations by phone are part of a scam. We want to ensure our members and the public that calls the may receive in the next few weeks are in fact legitimate and have been authorized and approved by the SCCPOA Board of Directors.</p>
                      <p>We want to thank everyone for supporting us this year. If you would like to donate, please click the link below to donate.</p>
                    </Col>
                    <Col md={4} className={cs.badge_container}>
                      <img className={cs.bobby_img} src={BobbyTaylor} />
                      <div className={cs.donate_button}><Button text={"Donate Now"} redirection={"/charitableFundraising"}/></div>
                    </Col>
                  </Row>
                </Card.Text>
              </Card>
            </Col>
          </Row>
          <hr />

          <Row className={cs.row}>
            <Col sm={12} md={8} className={cs.about_columns}>
              <Card className={cs.about_container}>
                <Card.Header className={cs.card_header}>
                  Youth Outreach
                </Card.Header>
                <Card.Text className={cs.card_body}>
                <p>
                  The SCCPOA Board Members organize and volunteer to work with our Campership Program, structured group outings with at-risk youth camping at our property. These campouts promote positive interaction between these at-risk youth and the law enforcement officers. This includes youngsters who may even have had a "brush" with the law, and may not necessarily have a positive feeling about law enforcement Officers from different agencies volunteer to "work" at the camp outs and this allows close contact between these youngsters and a law enforcement officer. 
                </p>
                <p>
                  For the most part these youngsters come from our local satellite group homes. Chaperones from the group home involved are required to attend. The Girl scouts, Boy Scouts and cub scouts use the property on a regular basis. Additionally, our campground and property is open all year. 
                </p> 
                <a href={"/youthOutreach"} className={cs.more_info}>{" "}Additional Information</a>

                
                </Card.Text>
              </Card>
            </Col>
            <Col sm={12} md={4} className={cs.about_columns}>
              <div className={cs.image_container}>
                <a href={"/youthOutreach"}>
                  <Card.Img
                    className={cs.about_img}
                    variant="top"
                    src={CampgroundHome}
                  />
                </a>
              </div>
            </Col>
          </Row>

          <hr />
          <Row className={cs.row}>
            <CardComponent
              src={Membership}
              header={"Membership Information"}
              href={"/newAndRenewal"}
            />
            <CardComponent
              src={MainRoadImg}
              header={"Road Conditions Update"}
              href={"/roadConditions"}
            />
            <CardComponent
              src={MainCabinImg}
              header={"Camping Information"}
              href={"/campground"}
            />
            <CardComponent
              src={MainPistolRangeImg}
              header={"Range Information"}
              href={"/range"}
            />
          </Row>

          <hr />
          <BusinessSupporters supporters={businessSupporters} />
        </Container>
        <Donations />
        <Footer />
        {
          // this.state.showPopup ?
          // <PopUp
          //           text='Click "Close Button" to hide popup'
          //           closePopup={this.togglePopup.bind(this)}
          // />
          // : null
        }
      </div>
    );
  }
}

export default Home;
