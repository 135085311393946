import React, {Component} from 'react';
import cs from './pageTitle.module.css';
import { faHome } from "@fortawesome/free-solid-svg-icons";

class PageTitle extends Component {
  render(){
    return (
      <div>
         <div className={cs.title}> {this.props.title} </div>
      </div>
    )
  }
}

export default PageTitle;
