import React, { Component } from "react";
import cs from "../membership.module.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";


class DonationTab extends Component {
    constructor(props) {
    super(props);
    this.state = {
      showDonationSelector: false,
      otherSelected: false
    }
    this.handleDonationYesCheckboxChange = this.handleDonationYesCheckboxChange.bind(this);
    this.handleDonationNoCheckboxChange = this.handleDonationNoCheckboxChange.bind(this);
    this.otherSelected = this.otherSelected.bind(this);
    this.preSetDonationSelected = this.preSetDonationSelected.bind(this)
    this.donationAmountChange = this.donationAmountChange.bind(this)
  }

  donationAmountChange(e) {
    const { cartDetails } = this.props;
    let donationAmount = e.target.value;
    if (isNaN(donationAmount)) return;

    if (donationAmount != "") {
      //rounds to two decimal places if not an empty string
      if (donationAmount > 99999) return;
      donationAmount = Math.round(e.target.value * 100) / 100;
      if (donationAmount <= 0) donationAmount = 0;

      donationAmount = parseFloat(donationAmount);
    }

    //sets the donation amount as a string

    cartDetails.donationAmount = donationAmount;
    this.props.setParentState({
      cartDetails,
      donationButtonDisabled: cartDetails.donationAmount <= 0,
    });
  }

  handleDonationYesCheckboxChange(event) {
    this.setState({showDonationSelector: true});
    this.props.setParentState({donationButtonDisabled: this.props.cartDetails.donationAmount <= 0});
  }

  handleDonationNoCheckboxChange(event) {
    let cartDetails = this.props.cartDetails;
    cartDetails.donationAmount = 0;
    this.setState({ showDonationSelector: false });
    this.props.setParentState({
      cartDetails,
      donationButtonDisabled: false,
      donationSelected: true
    });
  }

  preSetDonationSelected(e) {
    const {cartDetails} = this.props
    cartDetails.donationAmount = e.target.value;
    this.setState({otherSelected: false})
    this.props.setParentState({
      cartDetails,
      donationButtonDisabled: cartDetails.donationAmount <= 0,
    });
  }

  otherSelected(e) {
    this.props.cartDetails.donationAmount = 0;
    this.setState({otherSelected: true})
    this.props.setParentState({
      cartDetails: this.props.cartDetails,
      donationButtonDisabled: true
    });
  }

  render() {
    let {showDonationSelector, otherSelected} = this.state
    const {cartDetails} = this.props

    return (
      <div>
        <p className={cs.step_header}>
          Optional - SCCPOA Road Maintenance Donation
        </p>
        <p>
          The roads leading up to the campground and range need
          continual repair and maintenance. The SCCPOA has set up a
          designated fund for these road repairs. 100% of monies raised
          will go directly into a designated road repair fund, to be
          used exclusively for the road maintenance project. Your
          donation is also 100% tax deductible and you will receive a
          letter of receipt with the SCCPOA’s tax ID#EIN 94-6122042.
        </p>
        <p>
          The SCCPOA provides services to many organizations within
          Santa Clara County including underprivileged, disadvantaged,
          and/or at-risk youth that cater to the needs of local youth in
          order to interact in a positive way with law enforcement
          officers while using our property. With your commitment to
          this program, we can touch thousands of children here in Santa
          Clara County, not just for a span of days or weeks, but for a
          lifetime.
        </p>
        <Row className={cs.raffleTicketSelectionContainer}>
          {" "}
          <Col md={7}>
            <div>
              <label className={cs.document_label}>
                <input
                  type="radio"
                  name="donationGroup"
                  defaultChecked={false}
                  onChange={this.handleDonationYesCheckboxChange}
                />{" "}
                <span></span>
              </label>{" "}
              <span>Yes, I would like to make a donation.</span>{" "}
            </div>
            <div>
              <label className={cs.document_label}>
                <input
                  type="radio"
                  name="donationGroup"
                  onChange={this.handleDonationNoCheckboxChange}
                />{" "}
                <span></span>
              </label>{" "}
              <span>
                No, I would not like to participate at this time.
              </span>{" "}
            </div>
          </Col>
          <Col md={5}>
            {showDonationSelector && (
              <div>
                <div className={cs.payment_heading}>
                  Select Donation Amount Below:
                </div>
                <Row>
                  <Col md={4}>
                    <label className={cs.document_label}>
                      <input
                        type="radio"
                        onChange={this.preSetDonationSelected}
                        value={1000}
                        name="group1"
                      />{" "}
                      <span>$1000</span>
                    </label>{" "}
                  </Col>
                  <Col md={4}>
                    <label className={cs.document_label}>
                      <input
                        type="radio"
                        onChange={this.preSetDonationSelected}
                        value={500}
                        name="group1"
                      />{" "}
                      <span>$500</span>
                    </label>{" "}
                  </Col>
                  <Col md={4}>
                    <label className={cs.document_label}>
                      <input
                        type="radio"
                        onChange={this.preSetDonationSelected}
                        value={250}
                        name="group1"
                      />{" "}
                      <span>$250</span>
                    </label>{" "}
                  </Col>
                  <Col md={4}>
                    <label className={cs.document_label}>
                      <input
                        className={cs.document_label}
                        type="radio"
                        onChange={this.preSetDonationSelected}
                        value={100}
                        name="group1"
                      />{" "}
                      <span>$100</span>
                    </label>{" "}
                  </Col>
                  <Col md={4}>
                    <label className={cs.document_label}>
                      <input
                        type="radio"
                        onChange={this.preSetDonationSelected}
                        value={50}
                        name="group1"
                      />{" "}
                      <span>$50</span>
                    </label>{" "}
                  </Col>
                  <Col md={4}>
                    <label className={cs.document_label}>
                      <input
                        type="radio"
                        onChange={this.otherSelected}
                        name="group1"
                      />{" "}
                      <span>other</span>
                    </label>{" "}
                  </Col>
                  {otherSelected && (
                    <Col md={12}>
                      <span>
                        <span className={cs.payment_heading}>
                          Please enter desired amount:
                        </span>{" "}
                        $
                        <input
                          value={cartDetails.donationAmount}
                          onChange={this.donationAmountChange}
                          className={cs.other_text_field}
                        />
                      </span>
                    </Col>
                  )}
                </Row>
              </div>
            )}
          </Col>
        </Row>


      </div>
    	
    );
  }
}

export default DonationTab;




