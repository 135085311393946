import React, { Component } from "react";
import Header from "../../Common/Components/Header/header.jsx";
import Donations from "../../Common/Components/DonationPanel/donationPanel.jsx";
import Footer from "../../Common/Components/Footer/footer.jsx";
import PageTitle from "../../Common/Components/PageTitle/pageTitle.jsx";
import cs from "./appreciation.module.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TomRegan from "../../Common/assets/images/tomRegan.png";
import JeffBates from "../../Common/assets/images/JeffBates.png";
import Targets from "../../Common/assets/images/about/range targets.jpg";
import FNRAHotWaterHeater from "../../Common/assets/images/about/fnra_hot_water_heater.JPG";


class Appreciation extends Component {
  render() {
    return (
      <div>
        <Header />
        <Container>
          <PageTitle title="Appreciation" />
          <a className="no-underline" href="https://www.friendsofnra.org/">
          <Row className="justify-content-md-center">
            <Col class={cs.footer_txt} sm={5}>
              <img class={cs.img} src={FNRAHotWaterHeater} />
              <p>Water heater purchased with grant funds from Friends of the NRA</p>
              <p>September 9, 2023</p>
            </Col>
            <Col sm={6}>
              <h3>Friends of NRA</h3>

              <p>
                The users of the SCCPOA pistol range would like to express our 
                gratitude to the Friends of NRA for providing grant funds to 
                purchase a new water heater for the range restroom. Previously, 
                users washed their hands using ice cold water from our well. 
                The addition of this water heater makes for warm water available 
                for better cleansing of hands after a fun day at the range!
              </p>
            </Col>
          </Row>
          </a>
          <hr />
          <a className="no-underline" href="https://www.friendsofnra.org/">
            <Row className="justify-content-md-center">
              <Col class={cs.footer_txt} sm={5}>
                <img class={cs.img} src={Targets} />
                <p>
                  Targets purchased with grant funds from Friends of the NRA
                </p>
                <p>September 14, 2021</p>
              </Col>

              <Col sm={6}>
                <h3>Friends of NRA</h3>

                <p>
                  The SCCPOA Board of Directors would like to offer our
                  appreciation to the Friends of NRA for the grants they have
                  awarded to the SCCPOA over the years. With last year’s grant,
                  we were able to purchase and install new reactive steel
                  targets for the range. These targets give immediate feedback
                  to shooters that allow us to hone our skills. Young shooters
                  especially like the thrill of knocking down a reactive target
                  with that loud “clang” of confirmation. Thank you Friends of
                  NRA!
                </p>
              </Col>
            </Row>
          </a>
          <hr />
          <Row className="justify-content-md-center">
            <Col class={cs.footer_txt} sm={5}>
              <img class={cs.img} src={JeffBates} />
              <p>From left to right Bud Smith, Jeff Bates, Joe Charvez</p>
              <p>March 12, 2017</p>
            </Col>
            <Col sm={6}>
              <h3>Jeff Bates</h3>

              <p>
                Jeff Bates has been a member of the SCCPOA for four years and
                during those years; he has demonstrated his commitment to making
                our camping and range facility a better place. Jeff has attended
                almost all the monthly work parties since joining our
                association and spends a considerable amount of time locating
                and obtaining donated materials for ongoing projects at the
                property. Jeff recently purchased a new tractor tire and donated
                an additional two new tractor tires and rims for one of our
                older tractors. Additionally, Jeff has made several financial
                contributions to our road-rebuilding fund.
              </p>
            </Col>
          </Row>
          <hr />
          <Row className="justify-content-md-center">
            <Col class={cs.footer_txt} sm={5}>
              <img class={cs.img} src={TomRegan} />
              <p>From left to right Joe Charvez, Tom Regan, Bud Smith</p>
              <p>February 25, 2017</p>

              <p></p>
            </Col>
            <Col sm={6}>
              <h3>Tom Regan</h3>

              <p>
                Tom Regan has been a member of the SCCPOA for less than two
                years and he has demonstrated his commitment to improving our
                association property in many ways. Since joining, Tom has
                attended the majority of our monthly work parties and helps at
                our range facility by checking membership cards on a regular
                basis. Tom has also volunteered numerous additional days helping
                build a decorative rock platform in our shop facility that will
                hold a wood burning heater. Additionally, Tom has negotiated
                donations including several truckloads of road base material
                that he delivered himself up to our range facility, three
                pallets of paver bricks that will be used to make a walkway at
                our range, a full pallet of asphalt patch material to help fill
                potholes, and a pallet of decorative rock for our maintenance
                facility. Tom's commitment to the SCCPOA earned him our citizen
                of the year award for 2015.
              </p>
            </Col>
          </Row>
        </Container>
        <Donations />
        <br />
        <Footer />
      </div>
    );
  }
}

export default Appreciation;
