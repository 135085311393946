import React, { Component } from "react";
import Tab from "react-bootstrap/Tab";
import cs from "./membership.module.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import MembershipTab from "./components/membershipTab.jsx";
import RequiredDocumentsTab from "./components/requiredDocumentsTab.jsx";
import AddInsuranceTab from "./components/addInsuranceTab.jsx";
import DonationTab from "./components/donationTab.jsx";
import OrderSummaryTab from "./components/orderSummaryTab.jsx";
import CheckoutTab from "./components/checkoutTab.jsx";

import TabPane from "./components/tabPane.jsx";

var data = require("../../Data/membershipTypeDataNew.js");

class Memberships extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: 0,
      totalValue: 0,
      paypalCheckout: false,
      currentSelectedMemberType: null,
      // addContinueDisabeled: true,
      documentsReadCheck: false,
      agencyAgreementCheck: false,
      raffleTicketSelected: false,
      showRaffleTicketSelector: false,
      donationSelected: false,
      showDonationSelector: false,
      memberData: data.panels,
      otherSelected: false,
      isHidden: true,
      donationButtonDisabled: true,
      cartDetails: {
        membershipDetails: {
          price: 1,
          description: "",
        },
        addDetails: {
          isSelected: false,
          price: 15,
        },
        raffleTicketDetails: {
          totalTickets: 0,
          price: 100,
        },
        donationAmount: 0,
      },
      addInsuranceAvailable: false
    };

    this.preSetDonationSelected = this.preSetDonationSelected.bind(this);
    this.otherSelected = this.otherSelected.bind(this);
    this.handleADDYesCheckboxChange =
      this.handleADDYesCheckboxChange.bind(this);
    this.handleADDNoCheckboxChange = this.handleADDNoCheckboxChange.bind(this);
    this.handleRaffleYesCheckboxChange =
      this.handleRaffleYesCheckboxChange.bind(this);
    this.handleRaffleNoCheckboxChange =
      this.handleRaffleNoCheckboxChange.bind(this);
    this.handleDonationYesCheckboxChange =
      this.handleDonationYesCheckboxChange.bind(this);
    this.handleDonationNoCheckboxChange =
      this.handleDonationNoCheckboxChange.bind(this);
    this.handleMembershipRead = this.handleMembershipRead.bind(this);
    this.handleAgencyCheckAgreement =
      this.handleAgencyCheckAgreement.bind(this);
    this.handleMemberTypeClick = this.handleMemberTypeClick.bind(this);
    this.prevNextButtonClicked = this.prevNextButtonClicked.bind(this);
    this.membershipNumChanged = this.membershipNumChanged.bind(this);
    this.donationAmountChange = this.donationAmountChange.bind(this);
    this.getPaymentDescription = this.getPaymentDescription.bind(this);
    this.paypalfees = this.paypalfees.bind(this);
    this.toggleTextBlockHidden = this.toggleTextBlockHidden.bind(this);
    this.calculateTotal = this.calculateTotal.bind(this);
    this.calculatePaypalTotalWithTax =
      this.calculatePaypalTotalWithTax.bind(this);
    this.setParentState = this.setParentState.bind(this);
  }

  paypalfees(amount) {
    amount += 0.49;
    return amount / (1 - 0.0349);
  }

  calculateTotal() {
    const {
      membershipDetails,
      addDetails,
      raffleTicketDetails,
      donationAmount,
    } = this.state.cartDetails;

    let totalPrice =
      membershipDetails.price +
      addDetails.price * addDetails.isSelected +
      // raffleTicketDetails.price * raffleTicketDetails.totalTickets +
      parseFloat(donationAmount);
    return totalPrice;
  }

  calculatePaypalTotalWithTax(total) {
    return Math.round(this.paypalfees(total) * 100) / 100;
  }

  handleMemberTypeClick(memberType, price) {
    let cartDetails = this.state.cartDetails;
    cartDetails.membershipDetails = { price, description: memberType };

    let totalPrice = this.calculateTotal();

    this.setState({
      currentSelectedMemberType: memberType,
      cartDetails,
      totalValue: totalPrice,
    });
  }

  handleMembershipRead(e) {
    this.setState({ documentsReadCheck: !this.state.documentsReadCheck });
  }
  handleAgencyCheckAgreement(e) {
    this.setState({ agencyAgreementCheck: !this.state.agencyAgreementCheck });
  }

  setTab(tab) {
    this.setState({ currentTab: tab });
  }

  ticketOptionSelected(totalTickets) {
    let cartDetails = this.state.cartDetails;
    if (totalTickets == 0) {
      cartDetails.raffleTicketDetails = {
        totalTickets: undefined,
        price: 100,
      };
    } else {
      cartDetails.raffleTicketDetails = {
        totalTickets: totalTickets.value,
        price: 100,
      };
    }
    this.setState({ cartDetails });
  }

  toggleTextBlockHidden() {
    this.setState({ isHidden: !this.state.isHidden });
  }

  preSetDonationSelected(e) {
    const { cartDetails } = this.state;
    cartDetails.donationAmount = e.target.value;
    this.setState({
      otherSelected: false,
      cartDetails,
      donationButtonDisabled: cartDetails.donationAmount <= 0,
    });
  }
  otherSelected(e) {
    const { cartDetails } = this.state;
    cartDetails.donationAmount = 0;
    this.setState({
      cartDetails,
      otherSelected: true,
      donationButtonDisabled: true,
    });
  }

  handleADDYesCheckboxChange(event) {
    let cartDetails = this.state.cartDetails;

    cartDetails.addDetails = {
      isSelected: true,
      price: 15,
    };
    let totalPrice = this.calculateTotal();
    this.setState({
      cartDetails,
      addContinueDisabeled: false,
      totalValue: totalPrice,
    });
  }
  handleADDNoCheckboxChange(event) {
    let cartDetails = this.state.cartDetails;

    cartDetails.addDetails = {
      isSelected: false,
      price: 0,
    };
    let totalPrice = this.calculateTotal();
    this.setState({
      cartDetails,
      addContinueDisabeled: false,
      totalValue: totalPrice,
    });
  }
  handleRaffleYesCheckboxChange(event) {
    let cartDetails = this.state.cartDetails;
    cartDetails.raffleTicketDetails.totalTickets = 1;

    this.setState({
      cartDetails,
      raffleTicketSelected: true,
      showRaffleTicketSelector: true,
    });
  }
  handleRaffleNoCheckboxChange(event) {
    let cartDetails = this.state.cartDetails;
    cartDetails.raffleTicketDetails.totalTickets = 0;
    this.setState({
      cartDetails,
      raffleTicketSelected: true,
      showRaffleTicketSelector: false,
    });
  }
  handleDonationYesCheckboxChange(event) {
    this.setState({
      donationButtonDisabled: this.state.cartDetails.donationAmount <= 0,
      // donationSelected: true,
      showDonationSelector: true,
    });
  }
  handleDonationNoCheckboxChange(event) {
    let cartDetails = this.state.cartDetails;
    cartDetails.donationAmount = 0;
    this.setState({
      cartDetails,
      donationButtonDisabled: false,
      donationSelected: true,
      showDonationSelector: false,
    });
  }
  prevNextButtonClicked(tab) {
    this.setTab(tab);
    window.scrollTo(0, 0);
  }

  membershipNumChanged(membershipIdx, e) {
    let numMembershipsSelected = e.target.value;
    let memberData = this.state.memberData;
    memberData[membershipIdx].numOfMemberships = numMembershipsSelected;
    this.setState({ memberData });
  }

  donationAmountChange(e) {
    const { cartDetails } = this.state;
    let donationAmount = e.target.value;
    if (isNaN(donationAmount)) return;

    if (donationAmount != "") {
      //rounds to two decimal places if not an empty string
      if (donationAmount > 99999) return;
      donationAmount = Math.round(e.target.value * 100) / 100;
      if (donationAmount <= 0) donationAmount = 0;

      donationAmount = parseFloat(donationAmount);
    }

    //sets the donation amount as a string

    cartDetails.donationAmount = donationAmount;
    this.setState({
      cartDetails,
      donationButtonDisabled: cartDetails.donationAmount <= 0,
    });
  }

  getPaymentDescription() {
    let { membershipDetails, addDetails, raffleTicketDetails, donationAmount } =
      this.state.cartDetails;
    let newOrRenew =
      this.props.memberType == "new" ? "New Membership" : "Renewal Membership";
    let membershipDescription =
      newOrRenew +
      ", " +
      membershipDetails.description +
      " ($" +
      membershipDetails.price +
      ")";
    let addDescription = "";
    let raffleTicketDescription = "";
    let donationDescription = "";

    if (addDetails.isSelected)
      addDescription = ", AD&D Insurance ($" + addDetails.price + ")";
    // if (!!raffleTicketDetails.totalTickets)
    //   raffleTicketDescription =
    //     ", " +
    //     raffleTicketDetails.totalTickets +
    //     " Raffle Tickets ($" +
    //     raffleTicketDetails.price * raffleTicketDetails.totalTickets +
    //     ")";
    if (!!donationAmount)
      donationDescription = ", Donation ($" + donationAmount + ")";

    return (
      membershipDescription +
      addDescription +
      // raffleTicketDescription +
      donationDescription
    );
  }

  toTwoDecimal(num) {
    return parseFloat(num.toString()).toFixed(2);
  }

  setParentState(updatedState) {
    this.setState({ ...this.state, ...updatedState });
  }

  render() {
    const {
      currentTab,
      currentSelectedMemberType,
      cartDetails,
      documentsReadCheck,
      agencyAgreementCheck,
      donationButtonDisabled,
      addInsuranceAvailable
    } = this.state;
    const { memberType } = this.props;

    let total = this.calculateTotal();

    let tabComponentData = [
      {
        tabLabel: "Membership",
        buttonLabel: "Membership",
        nextButtonDisabled: !currentSelectedMemberType,
        component: (
          <MembershipTab
            cartDetails={cartDetails}
            calculateTotal={this.calculateTotal}
            setParentState={this.setParentState}
            currentSelectedMemberType={currentSelectedMemberType}
            handleMemberTypeClick={this.handleMemberTypeClick}
          />
        ),
      },
      {
        tabLabel: "Documents",
        buttonLabel: "Documents",
        nextButtonDisabled: false,
        component: (
          <RequiredDocumentsTab
            memberType={memberType}
            cartDetails={cartDetails}
          />
        ),
      },
      {
        tabLabel: "AD&D Insurance",
        buttonLabel: "AD&D Insurance",
        hidden: !addInsuranceAvailable,
        component: (
          <AddInsuranceTab
            cartDetails={cartDetails}
            calculateTotal={this.calculateTotal}
            setParentState={this.setParentState}
          />
        ),
      },
      // {
      //   tabLabel: "Raffle Tickets",
      //   buttonLabel: "Raffle Tickets",
      //   component: <RaffleTicketsTab cartDetails={cartDetails} />,
      // },

      {
        tabLabel: "Donation",
        buttonLabel: "Donation",
        nextButtonDisabled: donationButtonDisabled,
        component: (
          <DonationTab
            cartDetails={cartDetails}
            setParentState={this.setParentState}
          />
        ),
      },
      {
        tabLabel: "Order Summary",
        buttonLabel: "Summary",
        nextButtonDisabled: !(documentsReadCheck && agencyAgreementCheck),
        component: (
          <OrderSummaryTab
            cartDetails={cartDetails}
            memberType={memberType}
            calculateTotal={this.calculateTotal}
            handleAgencyCheckAgreement={this.handleAgencyCheckAgreement}
            handleMembershipRead={this.handleMembershipRead}
            addInsuranceAvailable={addInsuranceAvailable}
          />
        ),
      },
      {
        tabLabel: "Checkout",
        buttonLabel: "Payment",
        nextButtonDisabled: false,
        component: (
          <CheckoutTab
            cartDetails={cartDetails}
            calculateTotal={this.calculateTotal}
            calculatePaypalTotalWithTax={this.calculatePaypalTotalWithTax}
            toTwoDecimal={this.toTwoDecimal}
          />
        ),
      },
    ];
    tabComponentData = tabComponentData.filter(compData=>!compData.hidden)

    return (
      <Tab.Container activeKey={currentTab}>
        <Row className={cs.checkout_container}>
          <Col sm={12}>
            <Nav className="flex-row">
              {tabComponentData.map((compData, i) => {
                const index = i;
                return (
                  <Nav.Item className={cs.nav_item} key={index}>
                    <Nav.Link
                      eventKey={index}
                      className={
                        currentTab >= i ? cs.active_nav_link : cs.nav_link
                      }
                      onClick={this.setTab.bind(this, index)}
                      disabled={index > currentTab}
                    >
                      {compData.tabLabel}
                    </Nav.Link>
                    {index < tabComponentData.length - 1 && (
                      <hr
                        className={
                          currentTab > index
                            ? cs.completed_progress_line
                            : cs.incompleted_progress_line
                        }
                      />
                    )}
                  </Nav.Item>
                );
              })}
            </Nav>
          </Col>
          <Col sm={12}>
            <Tab.Content className={cs.tab_content}>
              {tabComponentData.map((compData, i) => {
                const index = i;
                const prevButtonLabel =
                  index > 0 ? tabComponentData[i - 1].buttonLabel : null;
                const nextButtonLabel =
                  index < tabComponentData.length - 1
                    ? tabComponentData[i + 1].buttonLabel
                    : null;
                return (
                  <TabPane
                    key={i}
                    index={i}
                    prevButtonLabel={prevButtonLabel}
                    nextButtonLabel={nextButtonLabel}
                    prevNextButtonClicked={this.prevNextButtonClicked}
                    nextButtonDisabled={compData.nextButtonDisabled}
                  >
                    {compData.component}
                  </TabPane>
                );
              })}
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    );

    // return (
    //   <Tab.Container activeKey={currentTab}>
    //     <Row className={cs.checkout_container}>
    //       <Col sm={12}>
    //         <Nav className="flex-row">
    //           <Nav.Item className={cs.nav_item}>
    //             <Nav.Link
    //               eventKey="1"
    //               className={currentTab >= "1" ? cs.active_nav_link : cs.nav_link}
    //               onClick={this.setTab.bind(this, 1)}
    //             >
    //               Membership
    //             </Nav.Link>
    //             <hr
    //               className={
    //                 currentTab > 1
    //                   ? cs.completed_progress_line
    //                   : cs.incompleted_progress_line
    //               }
    //             />
    //           </Nav.Item>

    //           <Nav.Item className={cs.nav_item}>
    //             <Nav.Link
    //               eventKey="2"
    //               className={currentTab >= 2 ? cs.active_nav_link : cs.nav_link}
    //               onClick={this.setTab.bind(this, 2)}
    //               disabled={2 > currentTab}
    //             >
    //               Documents
    //             </Nav.Link>
    //             <hr
    //               className={
    //                 currentTab > 2
    //                   ? cs.completed_progress_line
    //                   : cs.incompleted_progress_line
    //               }
    //             />
    //           </Nav.Item>

    //           <Nav.Item className={cs.nav_item}>
    //             <Nav.Link
    //               eventKey="3"
    //               className={currentTab >= 3 ? cs.active_nav_link : cs.nav_link}
    //               onClick={this.setTab.bind(this, 3)}
    //               disabled={3 > currentTab}
    //             >
    //               AD&D Insurance
    //             </Nav.Link>
    //             <hr
    //               className={
    //                 currentTab > 3
    //                   ? cs.completed_progress_line
    //                   : cs.incompleted_progress_line
    //               }
    //             />
    //           </Nav.Item>

    //           {/*
    //           <Nav.Item className={cs.nav_item}>
    //             <Nav.Link
    //               eventKey="4"
    //               className={
    //                 currentTab >= "4" ? cs.active_nav_link : cs.nav_link
    //               }
    //               onClick={this.setTab.bind(this, 4)}
    //               disabled={4 > currentTab}
    //             >
    //               Raffle Tickets
    //             </Nav.Link>
    //             <hr
    //               className={
    //                 currentTab > 4
    //                   ? cs.completed_progress_line
    //                   : cs.incompleted_progress_line
    //               }
    //             />
    //           </Nav.Item>
    //         */}

    //           <Nav.Item className={cs.nav_item}>
    //             <Nav.Link
    //               eventKey="4"
    //               className={
    //                 currentTab >= "4" ? cs.active_nav_link : cs.nav_link
    //               }
    //               onClick={this.setTab.bind(this, 4)}
    //               disabled={4 > currentTab}
    //             >
    //               Donation
    //             </Nav.Link>
    //             <hr
    //               className={
    //                 currentTab > 4
    //                   ? cs.completed_progress_line
    //                   : cs.incompleted_progress_line
    //               }
    //             />
    //           </Nav.Item>

    //           <Nav.Item className={cs.nav_item}>
    //             <Nav.Link
    //               eventKey="5"
    //               className={
    //                 currentTab >= "5" ? cs.active_nav_link : cs.nav_link
    //               }
    //               onClick={this.setTab.bind(this, 5)}
    //               disabled={5 > currentTab}
    //               // disabled={false}
    //             >
    //               Order Summary
    //             </Nav.Link>
    //             <hr
    //               className={
    //                 currentTab > 5
    //                   ? cs.completed_progress_line
    //                   : cs.incompleted_progress_line
    //               }
    //             />
    //           </Nav.Item>

    //           <Nav.Item className={cs.nav_item}>
    //             <Nav.Link
    //               eventKey="6"
    //               className={
    //                 currentTab >= "6" ? cs.active_nav_link : cs.nav_link
    //               }
    //               onClick={this.setTab.bind(this, 6)}
    //               disabled={6 > currentTab}
    //               // disabled={false}
    //             >
    //               Checkout
    //             </Nav.Link>
    //           </Nav.Item>
    //         </Nav>
    //       </Col>
    //       <Col sm={12}>
    //         <Tab.Content className={cs.tab_content}>
    //           <Tab.Pane eventKey="1">
    //             <span className={cs.numbered_info}>
    //               <p className={cs.step_header}>
    //                 Please select the membership you would like to purchase
    //                 below.
    //               </p>
    //               {memberData.map((panel, i) => (
    //                 <MemberTypePanel
    //                   key={i}
    //                   idx={i}
    //                   currentSelectedMemberType={currentSelectedMemberType}
    //                   handleMemberTypeClick={this.handleMemberTypeClick}
    //                   data={panel}
    //                   withPaypal={true}
    //                   numOfMemberships={0}
    //                   membershipNumChanged={this.membershipNumChanged}
    //                 />
    //               ))}
    //             </span>
    //             <div className={cs.next_prev_buttons_container}>
    //               <Button
    //                 className={cs.next_prev_buttons}
    //                 disabled={!currentSelectedMemberType}
    //                 onClick={this.prevNextButtonClicked.bind(this, 2)}
    //               >
    //                 {" "}
    //                 Continue To Documents{" "}
    //               </Button>
    //             </div>
    //           </Tab.Pane>

    //           <Tab.Pane eventKey="2">
    //             <p className={cs.step_header}>Required Documents</p>
    //             <p>
    //               {memberType === "new"
    //                 ? "To Process your New membership application, we need the following documents to be filled out and returned either by email (secretary@sccpoa.org) or by US mail (SCCPOA, PO Box 4629, Mt. View, CA 94040)."
    //                 : "To Process your Renewal membership application, we need the following documents to be filled out and returned either by email (secretary@sccpoa.org) or by US mail (SCCPOA, PO Box 4629, Mt. View, CA 94040)."}

    //               {/* Please fill out and send the "Membership Application
    //               Form", the "SCCPOA Range HHA", and a copy of your Law
    //               enforcement agency/department issued photo ID card (front
    //               and back side) to the address below or by email to:
    //               secretary@sccpoa.org. */}
    //             </p>
    //             <Row className={cs.confirmation_section}>
    //               <Col md={8}>
    //                 <ul>
    //                   <li className={cs.document_row}>
    //                     <PDFLink
    //                       src={hhaForm}
    //                       text={
    //                         memberType === "new"
    //                           ? "Range HHA (pdf)"
    //                           : "Range HHA (Required)"
    //                       }
    //                     />
    //                   </li>
    //                   <li className={cs.document_row}>
    //                     <PDFLink
    //                       src={ApplicationForm}
    //                       text={
    //                         memberType === "new"
    //                           ? "Membership Application Form (pdf)"
    //                           : "*Update Contact Information (Optional)"
    //                       }
    //                     />
    //                   </li>
    //                   {cartDetails.membershipDetails.description ===
    //                     "*Other" && (
    //                     <li className={cs.document_row}>
    //                       <span className={cs.li_text}>
    //                         Proof of firearms Training (Post, California BSIS,
    //                         ETC)
    //                       </span>
    //                     </li>
    //                   )}
    //                   {memberType === "new" && (
    //                     <li className={cs.document_row}>
    //                       <span className={cs.li_text}>
    //                         Clear and legible copy of your Law enforcement
    //                         agency/ department issued photo ID card (front and
    //                         back side)
    //                       </span>
    //                     </li>
    //                   )}
    //                 </ul>
    //               </Col>
    //             </Row>
    //             {memberType === "renew" && (
    //               <div>
    //                 *If your agency or contact information has changed in the
    //                 past year, (ie. agency, name, address, phone number, or
    //                 email), please fill out and send the "Update Contact
    //                 Information" Form and a copy of your Law enforcement
    //                 agency/department issued photo ID card (front and back side)
    //                 to the address below or by email to: secretary@sccpoa.org.
    //               </div>
    //             )}

    //             <div className={cs.next_prev_buttons_container}>
    //               <Button
    //                 className={cs.next_prev_buttons}
    //                 onClick={this.prevNextButtonClicked.bind(this, 1)}
    //               >
    //                 Back To Membership
    //               </Button>
    //               <Button
    //                 className={cs.next_prev_buttons}
    //                 onClick={this.prevNextButtonClicked.bind(this, 3)}
    //               >
    //                 Continue To AD&D Insurance
    //               </Button>
    //             </div>
    //           </Tab.Pane>

    //           <Tab.Pane eventKey="3">
    //             <span className={cs.numbered_info}>
    //               <p className={cs.step_header}>
    //                 Optional - AD&D (Accidental Death and Dismemberment
    //                 Insurance), Priced at $15/year:
    //               </p>
    //               <p>
    //                 Purchased Only Between December 1 – March 30th Annually.
    //                 Optional insurance is valued at $15,000. If you wish to
    //                 purchase optional AD&D insurance, you must fill out, sign,
    //                 and mail the "AD&D Insurance Beneficiary Form" linked below.
    //                 For more information please click on the "Insurance Summary"
    //                 pdf form.
    //               </p>
    //               <Row className={cs.payments_container}>
    //                 <Col sm={12} md={6}>
    //                   <PDFLink
    //                     src={InsuranceBeneficiaryForm}
    //                     text="AD&D Insurance Beneficiary Form (pdf)"
    //                   />
    //                 </Col>
    //                 <Col sm={12} md={6}>
    //                   <PDFLink
    //                     src={InsuranceSummary}
    //                     text="AD&D Insurance Summary (pdf)"
    //                   />
    //                 </Col>
    //               </Row>
    //               <div>
    //                 <label className={cs.document_label}>
    //                   <input
    //                     type="radio"
    //                     name="addGroup"
    //                     defaultChecked={false}
    //                     onChange={this.handleADDYesCheckboxChange}
    //                   />{" "}
    //                   <span></span>
    //                 </label>{" "}
    //                 <span>
    //                   Yes, I would like to purchase AD&D insurance coverage for
    //                   $15/year.
    //                 </span>{" "}
    //               </div>
    //               <div>
    //                 <label className={cs.document_label}>
    //                   <input
    //                     type="radio"
    //                     name="addGroup"
    //                     onChange={this.handleADDNoCheckboxChange}
    //                   />{" "}
    //                   <span></span>
    //                 </label>{" "}
    //                 <span>
    //                   No, I would not like to participate at this time.
    //                 </span>{" "}
    //               </div>
    //             </span>
    //             <div className={cs.next_prev_buttons_container}>
    //               <Button
    //                 className={cs.next_prev_buttons}
    //                 onClick={this.prevNextButtonClicked.bind(this, 2)}
    //               >
    //                 {" "}
    //                 Back to Documents{" "}
    //               </Button>

    //               <Button
    //                 className={cs.next_prev_buttons}
    //                 disabled={addContinueDisabeled}
    //                 onClick={this.prevNextButtonClicked.bind(this, 4)}
    //               >
    //                 {" "}
    //                 {/*Continue To Raffle Tickets{" "}*/}
    //                 Continue To Donation{" "}
    //               </Button>
    //             </div>
    //           </Tab.Pane>

    //           {
    //           <Tab.Pane eventKey="4">
    //             <p className={cs.step_header}>
    //               Optional - Raffle Ticket Purchase, Priced at $100/Ticket
    //             </p>
    //             <p>
    //               Each tax deductible raffle ticket purchase will be entered
    //               into the 2022 Road Maintenance Fund Raffle. 100% of the raffle
    //               proccedes are placed in a designated road fund. Raffle tickets
    //               are available for purchase through March 11th, 2022. The
    //               SCCPOA Raffle will be held on March 12th, 2022. Your will
    //               recieve your raffle tickets included with your 2022 membership
    //               packet. For more details, see the 2022 Road Maintenance Fund
    //               Raffle Flier PDF below.
    //             </p>
    //             <div className={cs.raffle_pdf}>
    //               <PDFLink
    //                 src={RaffleFlier}
    //                 text="2022 Road Maintenance Fund Raffle Flier (pdf)"
    //               />
    //             </div>

    //             <div
    //               className={cs.hiddenTextToggle}
    //               onClick={this.toggleTextBlockHidden}
    //             >
    //               {isHidden ? "See" : "Hide"} additional tax deduction
    //               information{isHidden ? " here" : ""}.
    //             </div>

    //             {!isHidden && (
    //               <div className={cs.hiddenTextBlock}>
    //                 {" "}
    //                 <p>
    //                   Note: The SCCPOA is a tax-exempt organization under
    //                   Section 501 c(4) of the Internal Revenue Code. This gift
    //                   is considered tax-deductible as a charitable contribution
    //                   for Federal Income Tax purposes. Federal Tax ID #
    //                   94-6122042.
    //                 </p>
    //                 <p>
    //                   Any charitable donation is 100% Tax Deductible! No funds
    //                   received by the association are used outside the County.
    //                   You may be able to deduct your donation as trade or
    //                   business expenses, if ordinary and necessary in the
    //                   conduct of the taxpayer’s business. Please consult your
    //                   tax advisor as to how your support may be deducted. If you
    //                   need a letter from us please email{" "}
    //                   <b>secretary@sccpoa.org</b>.
    //                 </p>
    //                 <p>
    //                   *Donation refund policy: Due to the nature of donations,
    //                   refunds are not offered. Secure Checkout is provided
    //                   through PayPal.
    //                 </p>
    //                 <p>Thank you for your support!</p>
    //               </div>
    //             )}

    //             <Row className={cs.raffleTicketSelectionContainer}>
    //               {" "}
    //               <Col md={7}>
    //                 <div>
    //                   <label className={cs.document_label}>
    //                     <input
    //                       type="radio"
    //                       name="raffleGroup"
    //                       defaultChecked={false}
    //                       onChange={this.handleRaffleYesCheckboxChange}
    //                     />{" "}
    //                     <span></span>
    //                   </label>{" "}
    //                   <span>
    //                     Yes, I would like to participate in the raffle.
    //                   </span>{" "}
    //                 </div>
    //                 <div>
    //                   <label className={cs.document_label}>
    //                     <input
    //                       type="radio"
    //                       name="raffleGroup"
    //                       onChange={this.handleRaffleNoCheckboxChange}
    //                     />{" "}
    //                     <span></span>
    //                   </label>{" "}
    //                   <span>
    //                     No, I would not like to participate at this time.
    //                   </span>{" "}
    //                 </div>
    //               </Col>
    //               <Col md={3}>
    //                 {showRaffleTicketSelector && (
    //                   <div>
    //                     <div className={cs.payment_heading}>
    //                       Select Ticket Quantity Below:
    //                     </div>
    //                     <Select
    //                       defaultValue={{ value: 1, label: "1 x $100" }}
    //                       options={[
    //                         { value: 1, label: "1 x $100" },
    //                         { value: 2, label: "2 x $200" },
    //                         { value: 3, label: "3 x $300" },
    //                         { value: 4, label: "4 x $400" },
    //                         { value: 5, label: "5 x $500" },
    //                         { value: 6, label: "6 x $600" },
    //                         { value: 7, label: "7 x $700" },
    //                         { value: 8, label: "8 x $800" },
    //                         { value: 9, label: "9 x $900" },
    //                         { value: 10, label: "10 x $1000" },
    //                       ]}
    //                       onChange={this.ticketOptionSelected.bind(this)}
    //                     />
    //                   </div>
    //                 )}
    //               </Col>
    //             </Row>

    //             <div className={cs.next_prev_buttons_container}>
    //               <Button
    //                 className={cs.next_prev_buttons}
    //                 onClick={this.prevNextButtonClicked.bind(this, 3)}
    //               >
    //                 Back To AD&D Insurance
    //               </Button>
    //               <Button
    //                 className={cs.next_prev_buttons}
    //                 onClick={this.prevNextButtonClicked.bind(this, 5)}
    //                 disabled={!raffleTicketSelected}
    //               >
    //                 Continue To Donation
    //               </Button>
    //             </div>
    //           </Tab.Pane>
    //         }

    //           <Tab.Pane eventKey="4">
    //             <p className={cs.step_header}>
    //               Optional - SCCPOA Road Maintenance Donation
    //             </p>
    //             <p>
    //               The roads leading up to the campground and range need
    //               continual repair and maintenance. The SCCPOA has set up a
    //               designated fund for these road repairs. 100% of monies raised
    //               will go directly into a designated road repair fund, to be
    //               used exclusively for the road maintenance project. Your
    //               donation is also 100% tax deductible and you will receive a
    //               letter of receipt with the SCCPOA’s tax ID#EIN 94-6122042.
    //             </p>
    //             <p>
    //               The SCCPOA provides services to many organizations within
    //               Santa Clara County including underprivileged, disadvantaged,
    //               and/or at-risk youth that cater to the needs of local youth in
    //               order to interact in a positive way with law enforcement
    //               officers while using our property. With your commitment to
    //               this program, we can touch thousands of children here in Santa
    //               Clara County, not just for a span of days or weeks, but for a
    //               lifetime.
    //             </p>
    //             <Row className={cs.raffleTicketSelectionContainer}>
    //               {" "}
    //               <Col md={7}>
    //                 <div>
    //                   <label className={cs.document_label}>
    //                     <input
    //                       type="radio"
    //                       name="donationGroup"
    //                       defaultChecked={false}
    //                       onChange={this.handleDonationYesCheckboxChange}
    //                     />{" "}
    //                     <span></span>
    //                   </label>{" "}
    //                   <span>Yes, I would like to make a donation.</span>{" "}
    //                 </div>
    //                 <div>
    //                   <label className={cs.document_label}>
    //                     <input
    //                       type="radio"
    //                       name="donationGroup"
    //                       onChange={this.handleDonationNoCheckboxChange}
    //                     />{" "}
    //                     <span></span>
    //                   </label>{" "}
    //                   <span>
    //                     No, I would not like to participate at this time.
    //                   </span>{" "}
    //                 </div>
    //               </Col>
    //               <Col md={5}>
    //                 {showDonationSelector && (
    //                   <div>
    //                     <div className={cs.payment_heading}>
    //                       Select Donation Amount Below:
    //                     </div>
    //                     <Row>
    //                       <Col md={4}>
    //                         <label className={cs.document_label}>
    //                           <input
    //                             type="radio"
    //                             onChange={this.preSetDonationSelected}
    //                             value={1000}
    //                             name="group1"
    //                           />{" "}
    //                           <span>$1000</span>
    //                         </label>{" "}
    //                       </Col>
    //                       <Col md={4}>
    //                         <label className={cs.document_label}>
    //                           <input
    //                             type="radio"
    //                             onChange={this.preSetDonationSelected}
    //                             value={500}
    //                             name="group1"
    //                           />{" "}
    //                           <span>$500</span>
    //                         </label>{" "}
    //                       </Col>
    //                       <Col md={4}>
    //                         <label className={cs.document_label}>
    //                           <input
    //                             type="radio"
    //                             onChange={this.preSetDonationSelected}
    //                             value={250}
    //                             name="group1"
    //                           />{" "}
    //                           <span>$250</span>
    //                         </label>{" "}
    //                       </Col>
    //                       <Col md={4}>
    //                         <label className={cs.document_label}>
    //                           <input
    //                             className={cs.document_label}
    //                             type="radio"
    //                             onChange={this.preSetDonationSelected}
    //                             value={100}
    //                             name="group1"
    //                           />{" "}
    //                           <span>$100</span>
    //                         </label>{" "}
    //                       </Col>
    //                       <Col md={4}>
    //                         <label className={cs.document_label}>
    //                           <input
    //                             type="radio"
    //                             onChange={this.preSetDonationSelected}
    //                             value={50}
    //                             name="group1"
    //                           />{" "}
    //                           <span>$50</span>
    //                         </label>{" "}
    //                       </Col>
    //                       <Col md={4}>
    //                         <label className={cs.document_label}>
    //                           <input
    //                             type="radio"
    //                             onChange={this.otherSelected}
    //                             name="group1"
    //                           />{" "}
    //                           <span>other</span>
    //                         </label>{" "}
    //                       </Col>
    //                       {otherSelected && (
    //                         <Col md={12}>
    //                           <span>
    //                             <span className={cs.payment_heading}>
    //                               Please enter desired amount:
    //                             </span>{" "}
    //                             $
    //                             <input
    //                               value={cartDetails.donationAmount}
    //                               onChange={this.donationAmountChange}
    //                               className={cs.other_text_field}
    //                             />
    //                           </span>
    //                         </Col>
    //                       )}
    //                     </Row>
    //                   </div>
    //                 )}
    //               </Col>
    //             </Row>

    //             <div></div>
    //             <div className={cs.next_prev_buttons_container}>
    //               <Button
    //                 className={cs.next_prev_buttons}
    //                 onClick={this.prevNextButtonClicked.bind(this, 3)}
    //               >
    //                 {/*Back To Raffle Tickets*/}
    //                 Back To AD&D Insurance
    //               </Button>
    //               <Button
    //                 className={cs.next_prev_buttons}
    //                 onClick={this.prevNextButtonClicked.bind(this, 5)}
    //                 disabled={donationButtonDisabled}
    //               >
    //                 Continue To Summary
    //               </Button>
    //             </div>
    //           </Tab.Pane>

    //           <Tab.Pane eventKey="5">
    //             <span className={cs.numbered_info}>
    //               <h2 className={cs.step_header}>Order Summary</h2>
    //               <Receipt
    //                 cartDetails={cartDetails}
    //                 subtotal={this.calculateTotal()}
    //                 toTwoDecimal={this.toTwoDecimal}
    //               />
    //               <h3 className={cs.task_header}>
    //                 Complete the following tasks to continue to payment:{" "}
    //               </h3>
    //               <div className={cs.agreement_conatainer}>
    //                 <div>
    //                   <input
    //                     type="checkbox"
    //                     defaultChecked={false}
    //                     onChange={this.handleMembershipRead}
    //                   />
    //                 </div>
    //                 <div className={cs.agreement}>
    //                   Yes, I have submitted
    //                   {cartDetails.membershipDetails.description === "*Other" &&
    //                     " proof of my firearms Training (Post, California BSIS, ETC), "}
    //                   {memberType === "new" &&
    //                     " a copy of my Law enforcement agency/ department issued photo ID card (front and back side), and "}
    //                   {cartDetails.membershipDetails.description === "*Other" &&
    //                     memberType === "renew" &&
    //                     "and "}{" "}
    //                   all of the documents below either by email
    //                   (secretary@sccpoa.org) or by US mail (SCCPOA, PO Box 4629,
    //                   Mt. View, CA 94040).
    //                 </div>
    //               </div>
    //               <Row className={cs.agreement_pdfs}>
    //                 <Col md={4}>
    //                   <PDFLink
    //                     src={ApplicationForm}
    //                     text={
    //                       memberType === "new"
    //                         ? "Membership Application Form (pdf)"
    //                         : "Update Contact Information (Optional)"
    //                     }
    //                   />
    //                 </Col>
    //                 <Col md={4}>
    //                   <PDFLink src={hhaForm} text="Range HHA (pdf)" />
    //                 </Col>
    //                 {cartDetails.addDetails.isSelected && (
    //                   <Col md={4}>
    //                     <PDFLink
    //                       src={InsuranceBeneficiaryForm}
    //                       text="AD&D Insurance Beneficiary Form (pdf)"
    //                     />
    //                   </Col>
    //                 )}
    //               </Row>
    //               <div className={cs.agreement_conatainer}>
    //                 <div>
    //                   <input
    //                     type="checkbox"
    //                     defaultChecked={false}
    //                     onChange={this.handleAgencyCheckAgreement}
    //                   />
    //                 </div>
    //                 <div className={cs.agreement}>
    //                   I hereby authorize the SCCPOA to confirm and verify my
    //                   Department status as a bonafide Police Agent or Officer
    //                   for the Agency listed above. I release any individual,
    //                   organization or agency from any and all liability incurred
    //                   as a result of providing such information.
    //                 </div>
    //               </div>
    //               <div className={cs.next_prev_buttons_container}>
    //                 <Button
    //                   className={cs.next_prev_buttons}
    //                   onClick={this.prevNextButtonClicked.bind(this, 4)}
    //                 >
    //                   Back To Donation
    //                 </Button>
    //                 <Button
    //                   className={cs.next_prev_buttons}
    //                   disabled={!(documentsReadCheck && agencyAgreementCheck)}
    //                   onClick={this.prevNextButtonClicked.bind(this, 6)}
    //                 >
    //                   Continue To Payment
    //                 </Button>
    //               </div>
    //             </span>
    //           </Tab.Pane>

    //           <Tab.Pane eventKey="6">
    //             <span className={cs.numbered_info}>
    //               <h2 className={cs.step_header}>Checkout</h2>

    //               <Receipt
    //                 cartDetails={cartDetails}
    //                 subtotal={this.calculateTotal()}
    //                 toTwoDecimal={this.toTwoDecimal}
    //               />

    //               <div>
    //                 <h4 className={cs.payment_total}>Payment</h4>
    //                 <Row>
    //                   <Col>
    //                     <ReactPayPal
    //                       className={cs.deactivated}
    //                       calculateTotal={() =>
    //                         this.calculatePaypalTotalWithTax(
    //                           this.calculateTotal()
    //                         )
    //                       }
    //                       getPaymentDescription={this.getPaymentDescription}
    //                       toTwoDecimal={this.toTwoDecimal}
    //                       // calculateSubtotal={this.calculateTotal}
    //                     />
    //                   </Col>
    //                   <Col>
    //                     <h4 className={cs.header}>
    //                       If paid by check ($
    //                       {this.toTwoDecimal(this.calculateTotal())})
    //                     </h4>
    //                     <h4 className={cs.header}>Remit payment to:</h4>
    //                     <p className={cs.check_content}>
    //                       Santa Clara County Peace Officer's Association
    //                       <br />
    //                       P.O. Box 4629
    //                       <br />
    //                       Mountain View, Ca 94040
    //                     </p>
    //                   </Col>
    //                 </Row>
    //               </div>

    //               <br />
    //               <p>
    //                 <b> SCCPOA Membership Refund/Cancellation Policy </b>{" "}
    //                 membership dues are non-refundable and memberships are
    //                 non-transferable. The Santa Clara County Peace Officers
    //                 Association reserves the right to refuse/cancel a membership
    //                 in the SCCPOA. If SCCPOA refuses a new or renewing
    //                 membership, registrants will be offered a refund.{" "}
    //                 <b> Information Collected:</b> Our website does not
    //                 automatically collect any individually identifiable
    //                 information.{" "}
    //                 <b>
    //                   Your privacy is important to us, and we are committed to
    //                   protecting it. We will never disclose or share your
    //                   personal information without your express written consent.
    //                 </b>{" "}
    //                 Secure Checkout is provided through PayPal. PayPal uses the
    //                 latest in data encryption and anti-fraud technology to keep
    //                 your information secure, reducing the risk of online fraud.
    //               </p>
    //               {
    //                 // this.state.currentSelectedMemberType && (
    //                 //   // <ReactPayPal total={1} />
    //                 //   <ReactPayPal
    //                 //     className={cs.deactivated}
    //                 //     total={this.state.totalValue}
    //                 //   />
    //                 // )
    //               }
    //             </span>
    //             <div className={cs.next_prev_buttons_container}>
    //               <Button
    //                 className={cs.next_prev_buttons}
    //                 onClick={this.prevNextButtonClicked.bind(this, 5)}
    //               >
    //                 Back To Summary
    //               </Button>
    //             </div>
    //           </Tab.Pane>
    //         </Tab.Content>
    //       </Col>
    //     </Row>
    //   </Tab.Container>
    // );
  }
}

export default Memberships;
