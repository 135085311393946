import React, {Component} from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import cs from './card.module.css'


class CardComponent extends Component {
	render(){
        let {src, header, href} =this.props
		return (
            
            <Col xs={6} sm={6} md={3} className={cs.card_container}>
                <a href={href}>
        			<Card className={cs.card}>
                        <div className={cs.image_container}><Card.Img className={cs.card_image} variant="top" src={src} /></div>
                        <div className={cs.card_header}> {header} </div>
                    </Card>
                </a>
            </Col>
        
		)
	}
}

export default CardComponent;
