import React, {Component} from 'react';
import cs from './footer.module.css';
import Container from 'react-bootstrap/Container';
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';
// import logo from '../../assets/images/poa_logo.png';
// import logoSCCPOF from '../../assets/images/SCCPOF_Logo.png';
import CS from '../../Styles/styles.module.css';

class Footer extends Component {
  render(){
    const year = new Date().getFullYear()
    return (
      <div>
            <Container className={cs.container}>
                <p>
                  Copyright &copy; SCCPOA {year}. All rights reserved
                  <br/>
                  Website built and managed by James and Amanda Rymer
                </p>

                <a href="/">Home</a> | 
                <a href="/about"> About Us</a> | 
                <a href="/contact"> Contact Us</a> | 
                <a href="https://www.google.com/maps/place/Santa+Clara+County+Peace+Officers+Association/@37.2433398,-122.1008326,12.83z/data=!4m5!3m4!1s0x0:0xc2be1fb7f3c27e98!8m2!3d37.249682!4d-122.0895582?hl=en"> Google Map</a>

            </Container>
      </div>
    )

    // return (
    // 	<div>
    //         <Container>

    //           <Row className="justify-content-md-center">
    //             <Col md={2}></Col>
    //             <Col md={1}><img className={cs.poa_img} src={logo}/></Col>
    //             <Col className={cs.footer_txt} md={6}>
    //             <p>
    //               Copyright &copy; SCCPOA 2020. All rights reserved
    //               <br/>
    //               Website built and managed by Amanda Tomasetti and James Rymer
    //             </p>
    //             <a href="/">Home</a> | 
    //             <a href="/about"> About Us</a> | 
    //             <a href="/contact"> Contact Us</a> | 
    //             <a href="https://www.google.com/maps/place/Santa+Clara+County+Peace+Officers+Association/@37.2433398,-122.1008326,12.83z/data=!4m5!3m4!1s0x0:0xc2be1fb7f3c27e98!8m2!3d37.249682!4d-122.0895582?hl=en"> Google Map</a>
    //             </Col>
    //             <Col md={1}><img className={cs.poa_img} src={logoSCCPOF}/></Col>
    //             <Col md={2}></Col>
    //           </Row>
    //         </Container>
    // 	</div>
    // )
  }
}

export default Footer;
