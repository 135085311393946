import React from 'react';  
import cs from './popUp.module.css'
import Card from 'react-bootstrap/Card';

class Popup extends React.Component {  
  render() {  
return (  
<div className={cs.popup}>  
	<div className={cs.popup_inner}>  
        <h3>Heavy Machinery Road Work On October 14th & 15th</h3>
        
        <p><h4>Recommended no usage on the dates above due to sticky oil and loose rock placed on the roadway</h4></p>

		<button className={cs.button + " btn btn-primary btn-lg"} onClick={this.props.closePopup}>close</button>  
	</div>  
</div>  
);  
}  
}  

export default Popup;