import React, {Component} from 'react';

import cs from '../boardMembers.module.css';
import boardOfDirectorsMembers from './boardOfDirectorsMembers.js'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


class BoadOfDirectors extends Component {
    render(){
        //console.log("boardOfDirectorsMembers", boardOfDirectorsMembers)
        const {chairPositions,directorAtLarge , directors} = boardOfDirectorsMembers
        return (
    	   <div>
                <div className={cs.flex_grid_thirds}>
                    {chairPositions.map(chairMember=>
                        <div className={cs.grid_col}>
                            <div className={cs.title}>{chairMember.title}</div>
                            <div className={cs.name}>{chairMember.name}, {chairMember.agency}</div>
                        </div>
                    )}
                </div>
                <br/>
                <div className={cs.title}> Director at Large </div>
                <div className={cs.name}>{directorAtLarge.name}, {directorAtLarge.agency}</div>
                <br/>
                <div className={cs.title}> Directors </div>
                <div className={cs.flex_grid_thirds}>
                    {directors.map(director=>
                        <div className={cs.grid_col}>
                            <div className={cs.name}>{director.name}, {director.agency}</div>
                        </div>
                    )}
                </div>
        	</div>
        )
    }
}
export default BoadOfDirectors;

