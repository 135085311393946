
const boardOfDirectorsMembers = {

	chairPositions: [
		{
			title: "President",
			name: "Bud Smith",
			agency:	"Sheriff Dept - Retired"
		},
		{
			title: "Vice President",
			name: "Joe Charvez",
			agency: "Sheriff Dept."
		},
		{
			title: "Secretary/Treasurer",
			name: "Deborah Tomasetti",
			agency: ""
		},
		{
			title: "Membership Chair",
			name: "Todd Trayer",
			agency: "SJPD"
		},
		{
			title: "Campership Chair",
			name: "Bobby Taylor",
			agency: "MVPD-Retired"
		},
		{
			title: "Fundraising Chair",
			name: "Ramsin Daniels",
			agency: "CHP-Retired"
		}
	],
	directorAtLarge: { name: "George Booth" , agency: "Breckenridge PD - Retired" },
	directors: [
		{ name: "Michael Gaynor" , agency: "SFPD-Retired" },
		{ name: "Bill Hoyt" , agency: "LGPD" },
		{ name: "Mark Good" , agency: "Gilroy PD-Retired" },
		
		{ name: "John Hernandez" , agency: "SJSUPD-Retired" },
		{ name: "Steve Canfield" , agency: "SO - Retired" },
		{ name: "Emmett Larkin" , agency: "SVDPS" },
	
		{ name: "Paul Jones" , agency: "Sheriff Dept.-Retired" },
		{ name: "Geeno Gular" , agency: "SJPD" },
		{ name: "Patrick McGee" , agency: "Secret Service" },
		
		{ name: "Michael Sanfilippo" , agency: "SVDPS-Retired" },
		{ name: "Daniel Davis" , agency: "FBI" },
		{ name: "John Korges" , agency: "LAPD-Retired" },
		
		{ name: "Rob Jones" , agency: "SCC Probation Officer-Retired" },
		{ name: "Todd Fleming" , agency: "LGPD" },
		{ name: "John Hansen" , agency: "LAPD-Retired" },
		
		{ name: "Ron Latham" , agency: "Gilroy PD-Retired" },
		{ name: "Ramsin Danies" , agency: "CHP - Retired" },
		{ name: "John Tomasetti" , agency: "SCPD Reserve - Retired" }
	]
}

export default boardOfDirectorsMembers

// directors: [
	// 	{ name: "Keith Cottrell" , agency: "SJPD - Retired - Dir At Large" },
	// 	{ name: "Daniel Davis" , agency: "FBI" },
	// 	{ name: "Todd Fleming" , agency: "LGPD" },
	// 	{ name: "Michael Gaynor" , agency: "SFPD - Retired" },
	// 	{ name: "Mark Good" , agency: "Gilroy PD" },
	// 	{ name: "Gary Goulart" , agency: "SO - Retired" },
	// 	{ name: "Geeno Gular" , agency: "SJPD" },
	// 	{ name: "John Hansen" , agency: "LAPD - Retired" },
	// 	{ name: "John Hernandez" , agency: "SJSUPD - Retired" },
	// 	{ name: "Bill Hoydt" , agency: "LGPD" },
	// 	{ name: "Rob Jones" , agency: "Parole Officer - Retired" },
	// 	{ name: "John Korges" , agency: "LAPD - Retired" },
	// 	{ name: "Emmett Larkin" , agency: "SVDPS" },
	// 	{ name: "Sam Miceli" , agency: "SCPD" },
	// 	{ name: "Michael Sanfilippo" , agency: "SVDPS - Retired" },
	// 	{ name: "Robert Smith" , agency: "PAPD - Retired" },
	// 	{ name: "Bobby Taylor" , agency: "MVPD" },
	// 	{ name: "Roger Watkins" , agency: "Parole Officer - Retired" },
	// 	{ name: "Paul Jones" , agency: "Sheriff Dept. - Retired" },
	// ],
	// directors: [

	// 	{ name: "Bill Hoydt" , agency: "LGPD" },
	// 	{ name: "Sam Miceli" , agency: "SCPD" },
	// 	{ name: "Geeno Gular" , agency: "SJPD" },
	// 	{ name: "Daniel Davis" , agency: "FBI" },
	// 	{ name: "Todd Fleming" , agency: "LGPD" },
	// 	{ name: "Bobby Taylor" , agency: "MVPD" },

	// 	{ name: "Mark Good" , agency: "Gilroy PD" },
	// 	{ name: "Emmett Larkin" , agency: "SVDPS" },
	// 	{ name: "Gary Goulart" , agency: "SO - Retired" },
	// 	{ name: "John Korges" , agency: "LAPD - Retired" },
	// 	{ name: "John Hansen" , agency: "LAPD - Retired" },
	// 	{ name: "Robert Smith" , agency: "PAPD - Retired" },
		
	// 	{ name: "Michael Gaynor" , agency: "SFPD - Retired" },
	// 	{ name: "John Hernandez" , agency: "SJSUPD - Retired" },
	// 	{ name: "Paul Jones" , agency: "Sheriff Dept. - Retired" },
	// 	{ name: "Michael Sanfilippo" , agency: "SVDPS - Retired" },
	// 	{ name: "Rob Jones" , agency: "Parole Officer - Retired" },
	// 	{ name: "Roger Watkins" , agency: "Parole Officer - Retired" },
	// 	{ name: "Keith Cottrell" , agency: "SJPD - Retired - Dir At Large" },
	// ]




	// directors: [
	// 	{ name: "Michael Gaynor" , agency: "SFPD - Retired" },
	// 	{ name: "Bill Hoydt" , agency: "LGPD" },
	// 	{ name: "Mark Good" , agency: "Gilroy PD" },
		
	// 	{ name: "John Hernandez" , agency: "SJSUPD - Retired" },
	// 	{ name: "Sam Miceli" , agency: "SCPD" },
	// 	{ name: "Emmett Larkin" , agency: "SVDPS" },
	
	// 	{ name: "Paul Jones" , agency: "Sheriff Dept. - Retired" },
	// 	{ name: "Geeno Gular" , agency: "SJPD" },
	// 	{ name: "Gary Goulart" , agency: "SO - Retired" },
		
	// 	{ name: "Michael Sanfilippo" , agency: "SVDPS - Retired" },
	// 	{ name: "Daniel Davis" , agency: "FBI" },
	// 	{ name: "John Korges" , agency: "LAPD - Retired" },
		
	// 	{ name: "Rob Jones" , agency: "Parole Officer - Retired" },
	// 	{ name: "Todd Fleming" , agency: "LGPD" },
	// 	{ name: "John Hansen" , agency: "LAPD - Retired" },
		
	// 	{ name: "Roger Watkins" , agency: "Parole Officer - Retired" },
	// 	{ name: "Bobby Taylor" , agency: "MVPD" },
	// 	{ name: "Robert Smith" , agency: "PAPD - Retired" },
		
	// 	{ name: "Keith Cottrell" , agency: "SJPD - Retired - Dir At Large" },
	// ]
