import React, { Component } from "react";
import Header from "../../Common/Components/Header/header.jsx";
import Donations from "../../Common/Components/DonationPanel/donationPanel.jsx";
import Footer from "../../Common/Components/Footer/footer.jsx";
import PageTitle from "../../Common/Components/PageTitle/pageTitle.jsx";
import DonateToday from "../../Common/Components/DonateToday/donateToday.jsx";

import cs from "./directDonations.module.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DirectDonationsImg from "../../Common/assets/images/charitableFundraising/SCCPOA_fundraising.jpg";
import PDFLink from "../../Common/Components/PDFLink/pdfLink.jsx";
import ThankYouForYourSupportSection from "../../Common/Components/ThankYouForSupportSection/thankYouForSupportSection.jsx"


class DirectDonations extends Component {
  displayParagraphs(letterParagraphs) {
    return letterParagraphs.map((paragraph) => {
      return (
        <p>
          <div dangerouslySetInnerHTML={{ __html: paragraph }}></div>
        </p>
      );
    });
  }

  render() {

    return (
      <div>
        <Header />
        <Container>
          <PageTitle title="Direct Donations, SCCPOA" />
          {/*<h3>
                      <b>Help Make a Difference in Our Community by supporting positive relationships between Bay Area Local Police Officers’ and Disadvantaged Youth!</b>
                    </h3>*/}
          {/* <img class={cs.img} src={TopImage}/>

                    <p className={cs.make_a_difference}>Help Make a Difference in Our Community by supporting positive relationships between Bay Area Local Police Officers’ and Disadvantaged Youth!</p>
                    {this.displayParagraphs(donationLetterParagraphs)} */}

          <p className={cs.paragraph_header}>SCCPOA Donations</p>
          <p>
            Your help is important. With your commitment to these programs, we can
            touch thousands of lives here in Santa Clara County, not just for a span of days
            or weeks, but for a lifetime. Please fill out and mail in the contribution card below.
          </p>

          <a href={"assets/pdf/fundraising/SCCPOA_fundraising.pdf"}>
            <img className={cs.img} src={DirectDonationsImg} />
          </a>
          <PDFLink
            src="assets/pdf/fundraising/SCCPOA_fundraising.pdf"
            text="Direct Donations"
          />
          <hr />
          <DonateToday title={"SCCPOA Direct Donations Here!"} paypalId={"7VDFM7LYQMVHN"}/>


          <ThankYouForYourSupportSection/>
        </Container>
        <Donations />
        <Footer />
      </div>
    );
  }
}

export default DirectDonations;
