import React, {Component} from 'react';
import Header from '../../Common/Components/Header/header.jsx';
import Donations from '../../Common/Components/DonationPanel/donationPanel.jsx'
import Footer from '../../Common/Components/Footer/footer.jsx'
import PageTitle from '../../Common/Components/PageTitle/pageTitle.jsx'
import cs from './charitableFoundation.module.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SCCPOFFlier from "../../Common/assets/images/charitableFoundation/SCCPOF_fundraising.jpg"
// import JohnHernandez from "../../Common/assets/images/fundraising/johnHernandez.jpg"
import ThankYouForYourSupportSection from "../../Common/Components/ThankYouForSupportSection/thankYouForSupportSection.jsx"
import PDFLink from "../../Common/Components/PDFLink/pdfLink.jsx";

class CharitableFoundation extends Component {
  render(){
    return (
    	<div >
    		<Header/>
        <Container>
          <PageTitle title="Fundraising Charitable Foundation, SCCPOF"/>
          <p>
            <b>The Santa Clara County Peace Officers' Foundation</b>, founded 1965 have been working in our
            Bay Area communities. The men and women of the SCCPOF are dedicated to fostering positive relationships between police and youth in our community.
          </p>
          <p className={cs.paragraph_header}>SCCPOF Mission Statement</p>
          <p> 
            The Santa Clara County Peace Officers’ Foundation provides services to many organizations within Santa Clara County including underprivileged, disadvantaged, and/or at-risk youth in order to interact in a positive way with law enforcement officers while using our property. The purpose of the Santa Clara County Peace Officers' Foundation is to provide financial support for the SCCPOA property and projects. Funds for these projects are raised through member contributions, participation by members and the community in fund raising events, and public fundraiser campaigns.
          </p>
          <hr/>
          <p className={cs.paragraph_header}>SCCPOF Donations</p>
          <p>
            Your help is important. With your commitment to these programs, we can
            touch thousands of lives here in Santa Clara County, not just for a span of days
            or weeks, but for a lifetime. Please fill out and mail in the contribution card below.
          </p>
          <a href="assets/pdf/charitableFoundation/SCCPOF_Fundraising.pdf">
            <img class={cs.img} src={SCCPOFFlier} alt={"SCCPOF Flier"}/>
          </a>
          <br/><br/>
          <PDFLink src="assets/pdf/charitableFoundation/SCCPOF_Fundraising.pdf" text="Fundraising Charitable Foundation, SCCPOF" />
          <hr/>

  
              <h3 align="left"> <b>Fundraising Charitable Foundation, SCCPOF Donations!</b></h3>
              <p className={cs.bold} style={{marginTop:"30px"}}>
                Donations should be sent by Paypal or by Check:
              </p>
              <Row>
                <Col>
                    <p className={cs.bold}>Payments made by PayPal:</p>
                    <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
                    <input type="hidden" name="cmd" value="_s-xclick"/>
                    <input type="hidden" name="hosted_button_id" value="NAGPY4GZBNKVC"/>
                    <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif" border="0" name="submit" alt="PayPal - The safer, easier way to pay online!"/>
                    <img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1"/>
                    </form>
                </Col>
                <Col>
                  <div>
                  <span className={cs.bold + " " + cs.margin_bottom}> Payments made by check to: </span>  
                  <p >
                    Santa Clara County Peace Officer's Foundation 
                    <br/>
                    P.O. Box 4629 
                    <br/>
                    Mountain View, Ca 94040 
                    <br/>
                  </p>
                  </div>
                </Col>
              </Row>
              <br/>
              <p>
                Note: The SCCPOF is a tax-exempt organization under Section 501 c(3) of the Internal Revenue Code. This gift is considered tax-deductible as a charitable contribution for Federal Income Tax purposes. Federal Tax ID # 82-5444902.             
              </p>
              <p>
                Any charitable donation is 100% Tax Deductible! No funds received by the association are used outside the County. You may be able to deduct your donation as trade or business expenses, if ordinary and necessary in the conduct of the taxpayer’s business. Please consult your tax advisor as to how your support may be deducted.
              </p>
              <p>
                *Donation refund policy: Due to the nature of donations, refunds are not offered. Secure Checkout is provided through PayPal. 
              </p>
              <p>
                If you have any questions please email <b>fundraising@sccpoa.org</b>.
              </p>
              {
                // <h3 align="left" >
                //   Donate Here Today!
                // </h3>

                // <p>
                //   Now you can help them continue their proud 50 plus year legacy of
                //   supporting and mentoring the youth of the Bay Area.
                // </p>


                // <p>
                //   <b>General Donations should be made online or checks sent to our P.O. box:</b>
                // </p>
                // <table >
                //   <tr>
                //     <th>Payments made online:</th>
                //     <th>Payments made by check to:</th>
                //   </tr>
                //   <tr>
                //     <td>
                //       <form action="https://santaclarapof.firstresponderprocessing.com/">
                //           <input src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif" type="image"  />
                //       </form>

                //     </td>
                //     <td>
                //       Santa Clara County Peace Officer's Foundation 
                //       <br/>
                //       P.O. Box 1058 
                //       <br/>
                //       San Jose, Ca 95108 
                //       <br/>
                //     </td>
                //   </tr>
                // </table>
                // <br/>
                // <p>
                //   The SCCPOF is a tax-exempt organization under Section 501 c(3) of the Internal Revenue Code.
                //   This gift is considered tax-deductible as a charitable contribution for Federal Income Tax purposes.  
                //   Federal Tax ID # 82-5444902.
                // </p>
                // <br/>
                // <p>
                //   *Donation refund policy: Due to the nature of donations, refunds are not offered.
                // </p>
                // <p>
                //   Thank you for your support!
                // </p>

              }


               {/* <img class={cs.img} src={JohnHernandez} alt={"Donation"}/> */}


          <ThankYouForYourSupportSection/>
          
        </Container>
        <Donations/>
        <Footer/>
      </div>
    )
  }
}

export default CharitableFoundation;

