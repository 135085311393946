import React, {Component} from 'react';
import Header from '../../Common/Components/Header/header.jsx';
import Donations from '../../Common/Components/DonationPanel/donationPanel.jsx'
import Footer from '../../Common/Components/Footer/footer.jsx'
import PageTitle from '../../Common/Components/PageTitle/pageTitle.jsx'
import cs from './volunteer.module.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import SolarPanelWork from '../../Common/assets/images/Volunteer/solarPanelWork.JPG'
import CompletedSolarPanelWork from '../../Common/assets/images/Volunteer/completedSolarPanelWork.JPG'


class Volunteer extends Component {
  render(){
    return (
      <div>
        <Header/>        
        <Container>
            <PageTitle title="Volunteer"/>
            <Row>
                <Col md={12} lg={9}>
                    <p>
                        Help restore and shape the landscape of our 171-are property with private campgrounds, hidden among the redwoods, off Highway 9 above the city of Saratoga. Tasks include property and range maintenance. There's always lots to do and lots to learn!
                    </p>
                    <p>
                        Work parties occur the second Saturday of every month from 9am - 4:00pm with an hour lunch break around noon. BBQ lunch will be provided. You can stop in at any point, or come for the whole thing!
                    </p>
                    <p>
                        Most days will be filled with some sort of physical activity and take place outdoors, rain or shine, so bring weather appropriate clothing and shoes.
                    </p>
                    <p>
                        Work party date or not, if there is a property improvement project you, with or without others, would like to do (i.e.: rebuilding the campfire area, clearing and remarking the hiking trails, or any improvements in the campground area etc.) call us at 264-1224 so the project and supplies can be discussed.
                    </p>
                    <p>
                        For more information send an email to: admin@sccpoa.org
                    </p>
                </Col>
                <Col md={12} lg={3}>
                    {/*<img className={cs.img} src="assets/images/volunteer.jpg"/>*/}
                    <Row>
                        <Col md={6} lg={12}>
                            <img className={cs.img} src={SolarPanelWork}/>
                        </Col>
                        <Col md={6} lg={12}>
                            <img className={cs.img} src={CompletedSolarPanelWork}/>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
        <Donations/>
        <Footer/>
      </div>
    )
    
  }
}

export default Volunteer;
